import { useEffect, useRef, useState } from "react";

import nextArr from '../../assets/ALeft.png'
import prevArr from '../../assets/ARight.png'

import dummy from '../../assets/samples/body.png'
import dummy1 from '../../assets/samples/BODY1.png'
import dummy2 from '../../assets/samples/body2.png'

import aim from '../../assets/samples/anima.gif'
// import { dummyImg } from '../../Data'

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';

import logo from '../../assets/logo_m.svg'
import M from '../../assets/icon/magnifying.svg'
import War from '../../assets/icon/w.svg'
// import warning1 from '../../assets/icon/warning.svg'

import '../fabric.css'

import default_shirt from '../../assets/samples/shirt/SHIRT.png'
import default_shirt1 from '../../assets/samples/shirt/SHIRT.png'


function ViewImages({sel, single, loading, setLoading, imgState, setImgState, type, isMobile, setState1, jodh}) {
    // const [imgState, setImgState] = useState(0)
    const [Single, setSingle] = useState()
    const [state, setState] = useState(1)

    const [magnify, setMagnify] = useState(false)
    const [[x, y], setXY] = useState([0, 0])

    const magnifyStyle = (X, Y) => ({
        transform:`scale(3)`,
        left: `${X}%`,
        top: `${Y}%`
      });

    useEffect(()=>{ 
        // console.log("type received ", type)

        

        if(type === 1){
            // const p = ["Coat", "Vest", "Pant", "Shirt"]
            const p = sel.types
            // console.log("coming here to fetch single")
            let data = []
            p.forEach((typS)=>{
                
                data = [...data, ...sel["fabric"][typS]["single"]]
            })
            // console.log("data from profile", data)
            setSingle(data)
            setState1(data.length + 1)
        }else{
            setState(single.length + 1)
        }
    }, [])
    const counter = useRef(0);
    const imageLoaded = () => {
        counter.current += 1;
        let L = 0;
        if(imgState === 0){
            if(sel && sel.types && sel.types.includes("Vest")){
                L = sel.types.length + 2    
            }else{
                L = sel.types.length + 1
            }

            if(jodh){
                L = L-1;
            }
            
            // L = sel.types.length
        }else{
            L = 1
        }
        
        if (counter.current >= L) {
            setLoading(false);
            counter.current = 0;
        }
    }

    return ( 
        <div className="v-d-body" id="display_image"
            

            onClick={()=>{
                if(!isMobile){
                    setMagnify(!magnify)
                }
                
            }}

            onMouseLeave={()=>{
                setMagnify(0)
            }}

            onMouseMove={(e)=>{
                
                const element = document.getElementById("display_image")
                
                var rect = element.getBoundingClientRect();
                

                
                var x = e.pageX - rect.left; //x position within the element.
                var y = e.pageY - rect.top;
                const pX = ((x)/rect.width)*100
                const pY=  ((y)/rect.height)*100
                // // console.log(rect)
                // // console.log("X:", x, "Y:", y, "max:", e.pageX, e.pageY)

                const left = Math.round(((-3)*(pX) / 5) + 30)
                const top = Math.round((100 - 2*pY))
                
                // // console.log(left, top)
                setXY([left, top])

                }}
            
            
        >

            <div style={{"position":"absolute", "zIndex":"100"}}>
                <Tooltip TransitionComponent={Zoom} title="Visible Images are Renderd. It May be different from actual product. Open Fabric info to see real images of Fabric ">
                    {/* <Button>Zoom</Button> */}
                    <img src={War} style={{"width":"20px", "opacity":"1"}}/>
                </Tooltip>
            </div>
            {!isMobile && <div style={{"position":"absolute","top":"40px", "zIndex":"100"}}>
                <Tooltip TransitionComponent={Zoom} title="Click on the Image to Zoom">
                    {/* <Button>Zoom</Button> */}
                    <img src={M} style={{"width":"20px", "opacity":"1"}}/>
                </Tooltip>
            </div>}

            
                    

            {/* https://stackoverflow.com/questions/56902522/react-show-loading-spinner-while-images-load */}
            {(loading) && (<div className='f-loading'   
            >
                {/* <div>
                <img src={logo} style={{"width":"40px", "position":"absolute"}} /> */}
                <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
                {/* </div> */}
            </div>)}
            {sel && sel.types && imgState === 0 ? (
                <>
                    {/* <img src={shirt} className='v-display-img-full'/>
                    <img src={jacket} className='v-display-img-full'/>
                    <img src={coat} className='v-display-img-full'/>
                    <img src={pant} className='v-display-img-full'/> */}
                    <img src={dummy2} className='v-display-img-full hori_center dummy first_img_mob'
                        onLoad={imageLoaded}
                        

                        style={!sel.types.includes("Pant") || imgState > 0 ?{"display":"none"}: magnify ? magnifyStyle(x, y) : {}}
                    />
                    <img src={default_shirt1} className={`v-display-img-full hori_center first_img_mob dummy ${isMobile ? 'mobile-image1' : ''} ${jodh ? `jodh_shirt`:''} `}
                        onLoad={imageLoaded}
                        style={
                            !sel.types.includes("Pant") || imgState > 0 || sel.types.includes("Shirt") ?{"display":"none"}:magnify ? magnifyStyle(x, y) : {} 
                        }
                    />
                    {sel.types.map((val, key)=>{
                        return(
                            <img src={ sel && sel.fabric && Object.hasOwn(sel.fabric, val) ? sel.fabric[val]["src"] : "" }
                                onLoad={imageLoaded}
                                style={ magnify ? {...magnifyStyle(x, y), ...{"zIndex":50-sel.fabric[val]["pos"]}} : 
                                     {"zIndex":50-sel.fabric[val]["pos"]} } 

                                className={`${sel.types.includes("Pant") ? "v-display-img-full first_img_mob hori_center" : "v-display-img"} ${isMobile ? 'mobile-image2' : ''}`}/>
                        )
                    })}
                </>
            ): imgState > 0 ?
            (<>

            {/* 1. Coat
            2. vest
            3. Pant
            4. Shirt */}
            
                {/* <img src={s2} className='v-display-img-full' onLoad={imageLoaded}/>
                <img src={s5} className='v-display-img-full' onLoad={imageLoaded}/>
                <img src={s3} className='v-display-img-full' onLoad={imageLoaded}/>
                <img src={s1} className='v-display-img-full' onLoad={imageLoaded}/> */}
                {/* {type && type === 0 ? (<>
                
                </>)
                : (<></>)
                } */}
                { type === 0 && single && single[imgState-1]["typ"] === 0 ? (
                    <img src={single[imgState-1]["src"]} className={`v-display-img-full hori_center ${isMobile ? 'mobile-image' : ''} ${isMobile && sel.types.includes("Shirt") && imgState === 3 ? "shirt_mob_scale" : ""}`}
                        onLoad={imageLoaded}

                        style = {magnify ? magnifyStyle(x, y):{}}
                    />
                ) : (<>
                    {type===0 && single && single[imgState-1]["src"].map((imgV, imgKey)=>{
                        return(
                            <img src={imgV} className={`v-display-img-full hori_center ${isMobile ? 'mobile-image' : ''} ${isMobile && sel.types.includes("Shirt") && imgState === 3 ? "shirt_mob_scale" : ""}`}
                                onLoad={imageLoaded}

                                style = {magnify ? magnifyStyle(x, y):{}}
                            />

                            
                        )
                    })

                    }
                </>)

                }

                {type && type === 1 ? (<>
                    {Single && Single[imgState-1]["typ"] === 0 ? (
                    <img src={Single[imgState-1]["src"]} className={`v-display-img-full ${isMobile ? 'mobile-image' : ''} ${isMobile && sel.types.includes("Shirt") && imgState === 3 ? "shirt_mob_scale" : ""}`}
                        style={loading ? {"display":"none"} : {} }
                        onLoad={imageLoaded}
                    />
                ) : (<>
                    {type === 1 && Single && Single[imgState-1]["src"].map((imgV, imgKey)=>{
                        return(
                            <img src={imgV} className={`v-display-img-full ${isMobile ? 'mobile-image' : ''}${isMobile && sel.types.includes("Shirt") && imgState === 3 ? "shirt_mob_scale" : ""}`}
                                style={loading ? {"display":"none"} : {} }
                                onLoad={imageLoaded}
                            />
                        )
                    })

                    }
                </>)
                }
                </>)
                : (<></>)
                }
                
                
            </>)
            : (<></>)
            
            }
            
            

        </div> 
    );
}

export default ViewImages;