import { useNavigate } from 'react-router-dom';
import './checkout.css'

function Done({data}) {
    const navigate = useNavigate()

    return ( 
        <div className="payment_process both_center">
        <div>   <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
       </svg>
       </div>
           <h2>Payment Success</h2>
           <h2>Order Received</h2>
           <div>
                <b><div>OrderID  #{data.orderId ? data.orderId : ""}</div></b>
                <div>Name : {data.name ? data.name : ""}</div>
                {data.email && <div>Email : {data.email ? data.email : ""}</div>}
                {data.phone && <div>Phone Number : {data.phone ? data.phone : ""}</div>}
                <div>Items : {data.items? data.items : ""}</div>

           </div>
           

           <br/>
           <a href="/profile" style={{textDecoration:"none"}}>
           <button className="Butt1 hori_center" 
               onClick={()=>{
               }}
           ><span>View Order</span></button>
           </a>
       </div> );
}

export default Done;