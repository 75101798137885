import Done from "./done"

import aim from '../assets/samples/anima.gif'

function Payments({payment}) {



	return (
		<div className="payment" style={{height:"100vh", width:"100%"}} >
            
			{/* <div className="payment_confirm"> */}
				{payment["id"] === 0 ? (
					<div className="payment_process both_center">
						<img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
						<h2>Please Wait</h2>
						<h2>Processing your order</h2>       
					</div>) 
				: payment["id"] === 1 ? (
					<Done
						data={payment}
					/>) 
				: payment["id"]===-1 ? (<div><br/><br/><br/>Payment Failed</div>) : (
				<div className="payment_process both_center">
						<img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
						<h2>Please Wait</h2>
						<h2>Processing your order</h2>       
					</div>
				)

				}
		</div>
	)
}

export default Payments






	

