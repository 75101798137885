import Order_status from './order_status';
import './profile.css'

import sample from '../assets/samples/s1.png'
import { useRef, useState } from 'react';

import body from '../assets/samples/body2.png'
import def_shirt from '../assets/samples/shirt/SHIRT.png'

import aim from '../assets/samples/anima.gif'

import support from '../assets/icon/support.svg'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const orderStatus = ["Order Received", "Order Confirmed", "Order Processing", "In Transit", "Order Delivered"]

function Order_card({order, setOrder, setModal, setHelp}) {

    const [showFullText, setShowFullText] = useState(false);
    const [date, setDate] = useState([(new Date(order.Date)).getFullYear(), 
        (new Date(order.Date)).getMonth(),
        (new Date(order.Date)).getDate()])
    
    const [statusDate, setStatusDate] = useState()

    const [load, setLoad] = useState(true)
    const counter = useRef(0);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

    useState(()=>{
        if(order.status){
            setStatusDate([(new Date(order.status.Date)).getFullYear(), 
                (new Date(order.status.Date)).getMonth(),
                (new Date(order.status.Date)).getDate()])
        }
    }, [])
    const imageLoaded1 = () => {
        counter.current += 1;
        let L
        if(!order.types){
            L=1;
        }else{
            L = order.types.length;
        }
         
        
        
        if (counter.current >= L) {
            setLoad(false);
            counter.current = 0;
        }
    }

    const handleToggleText = () => {
        setShowFullText(!showFullText);
    };
    return ( 
    <div className="order_card">

        <img src={support} className='order_card_support' type='button'
        
            onClick={()=>{

                console.log( {orderId:order.orderId, "id":-1, order:order, statusDate:statusDate, date:date}  )
                setHelp({orderId:order.id, "id":-1, order:order, statusDate:statusDate, date:date})
            }}
        />
        <div className="o-c-t">
            <div className='order-id-mob'>
            <div className="o-c-t-c ">
                <div>ORDER PLACED</div>
                <div>{date[2]}, {months[date[1]]} {date[0]}</div>
            </div>
            </div>
            <div className="o-c-t-c total-mob">
                <div>TOTAL</div>
                <div>
                { Object.keys(order).includes("item_type") && order.item_type === 0 ? 
                <>
                    {Number(250).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                        })}</>
                    :
                
                    <>{Number(order.price).toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                    })}</>
                    }
                </div>
            </div>
            <div className="o-c-t-c1">
                <label>ORDER # </label>
                <label>{order.id}</label>
            </div>
        </div>
        <div className="o-c-d">
            <div className='order-img'>
                {(load) && (<div className='f-loading' style={{position:"absolute", textAlign:"center", backgroundColor:"white", zIndex:"1"}}
                >
                    <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
                </div>)}
                {order && <>
                    <img src={ body }
                                            // onLoad={imageLoaded} 
                        style={!load && !Object.keys(order).includes("item_type") ? {}:{display:"none"}}
                        className= {"o-c-d-img"}/>
                    <img src={ def_shirt }
                                            // onLoad={imageLoaded} 
                        style={!load && !Object.keys(order).includes("item_type") ? {}:{display:"none"}}
                        className= {"o-c-d-img"}/>
                    {order && Object.keys(order).includes("item_type") && order.item_type === 1  ? 
                    
                        <img 
                            src={order.src}
                            onLoad={imageLoaded1}
                            className= "o-c-d-img"
                        />

                    : order && Object.keys(order).includes("item_type") && order.item_type === 0  ? 
                    <img 
                            src={order.src}
                            onLoad={imageLoaded1}
                            className= "o-c-d-img"
                            style={{objectFit:"contain"}}
                        />
                    : order && !Object.keys(order).includes("item_type") ?
                        <>
                            {order && order.types && order.types.map((val, key)=>{
                                    return(
                                        <img src={ order && order.fabric && Object.hasOwn(order.fabric, val) ? order.fabric[val]["src"] : "" }
                                            onLoad={imageLoaded1}
                                            
                                            style={ !load ? {"zIndex":50-order.fabric[val]["pos"]} : {"display":"none"} } 
                                            className= {order.types.includes("Pant") ? "o-c-d-img":"o-c-d-img"}/>
                                    )
                            })}
                        </>
                        : <></>
                    }
                    
                    {/* <img src={order["fabric"]["Coat"]["src"]} className='o-c-d-img'/>
                    <img src={order["fabric"]["Pant"]["src"]} className='o-c-d-img'/> */}
                </>}
            </div>
            <div className="o-c-d-des FIT_W">
                <div className='verti_center' style={{"height":"fitContent"}}>
                <label className='txt-font1'>{order.name}</label><br/>
                
                            <div className='less-mob'>
                                Order Includes : {!Object.keys(order).includes("item_type") && order.types.map((val)=>{
                                    return(<span> {val}, </span>)
                                })}
                                { Object.keys(order).includes("item_type") && order.item_type === 1 ? 
                                    <></> 
                                    
                                    :Object.keys(order).includes("item_type") && order.item_type === 0 ?  <>Fabric only</> : <></>

                                } 
                                
                                <br/> Click View Details to see Order info
                                
                                
                                <div className='FIT_W'>
                                    
                                    { !Object.hasOwn(order,"status") ? 
                                            <>{date[2]}, {months[date[1]]} {date[0]}</>
                                        : statusDate ? <>{statusDate[2]}, {months[statusDate[1]]} {statusDate[0]}</> : ""
                                    } -
                                    <b>{Object.hasOwn(order,"status") ? `${orderStatus[order.status.status]}`:`${orderStatus[0]}`}</b>
                                </div>
                                
                                {!isMobile &&  <>
                                <br/>

                                Order Tracking 
                                <br/>
                                <Order_status status={ order.status ? order.status.status : 0  }/> </>}
                                
                            </div>
                        
                </div>
            </div>
            
            <div className='o-c-d-b'>
                <button className="Butt1 padd_butt track-mob"> <span>Download invoice</span> </button>
                <button className="Butt2 padd_butt view-mob"
                    onClick={()=>{
                        setModal(1)
                        setOrder(order)
                    }}
                > <span>Order details</span>  </button>
            </div>
        </div>
        

        
        
    </div> 
    );
}

export default Order_card;