import { useState } from "react";
import Foot from "../footer";
import Appointment from "./appointment";

import insta from '../assets/icon/social/instagram_c.png'
import fb from '../assets/icon/social/facebook_c.png'
import pin from '../assets/icon/social/pinterest_c.png'
import twitter from '../assets/icon/social/twitter_c.png'
import yt from '../assets/icon/social/youtube_c.png'
import whatsapp from '../assets/icon/social/whatsapp_c.png'

function Contact() {

    const [social, setSocial] = useState([{"src":insta, "link":"https://www.instagram.com/maviinci.in/"},
        {"src":fb, "link":"https://www.facebook.com/people/maviinciin/61556379716484/"},
        {"src":pin, "link":"https://in.pinterest.com/maviincifashion/"},
        {"src":twitter, "link":"https://x.com/maviincifashion?t=3Hy6jKr1NxuBUemXqMeYUA&s=08"}, 
        {"src":yt, "link":"https://www.youtube.com/@MAVIINCI_FASHION"}, 
        {"src":whatsapp, "link":"https://wa.me/918871081395"}, 
        ] ) 

    return ( <div className="Contact_page">

        <div className="c_top">
            
            <label className="FIT_W hori_center">
                Contact Us
            </label>
        </div>        

        <div className="row_cards hori_center">
            <div className="c_card">
                <div className="txt-font1 hori_center">Reach Us At</div>
                <br/>
                <table>
                    <tr>
                        <td>Email</td>
                        <td>isupport@bluepearlindustries.com</td>
                    </tr>
                    <tr>
                        <td>phone</td>
                        <td>+91-8871081395</td>
                    </tr>
                </table>
                <br/>
                <div className=''>
                    Address : <br/>
                    Shanti Nagar Bhilai-3<br/>
                    Dist-durg chhattisgarh <br/>
                    Pin 490021<br/>
                </div>

            </div>
            <div className="c_card">
                <div className="txt-font1 hori_center">Company and Branding</div>
                <br/>
                <div>Blue Pearl Industries Inc.</div>
                <div className="c_logos_row hori_center">
                    {social.slice(0, 3).map((val)=>{
                        return(
                            <div className="c_s_logos"
                                type="button"
                            >
                                <a href={val.link}>
                                <img src={val.src} className=""/>
                                </a>
                                <div>Instagram</div>
                            </div>
                        )
                    })

                    }
                </div>
                <div className="c_logos_row hori_center">
                    {social.slice(3).map((val)=>{
                        return(
                            <div className="c_s_logos"
                                type="button"
                            >
                                <a href={val.link}>
                                <img src={val.src} className=""/>
                                </a>
                                <div>Instagram</div>
                            </div>
                        )
                    })

                    }
                </div>
            </div>
        </div>
        <div className="row_cards_2">
            
        
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243647.34203589996!2d78.24323185623166!3d17.41228101518494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1728132930340!5m2!1sen!2sin"
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade"
                className="c_location"
                >
            </iframe>
            <div className = "c_app">
                <Appointment/>
            </div>
        </div>
        <Foot/>
    </div> );
}

export default Contact;