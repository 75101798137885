import { useEffect, useMemo, useState,useRef, useContext } from "react";
import NavBar from "../Navbar";

import {getState, getUser, logout} from '../Firebase/auth'
import { useNavigate } from "react-router-dom";
import './profile.css'
import Order_card from "./order";

import close from '../assets/icon/close.png'

import nextArr1 from '../assets/icon/Larrow.png'
import prevArr1 from '../assets/icon/Rarrow.png'

import nextArr from '../assets/icon/nextL.png'
import prevArr from '../assets/icon/next.png'

import '../Firebase/auth'
import { db } from "../firebase";
import { getDocOnCondition } from "../Firebase/firestoreGet";
import ViewImages from "../Fabric/View/viewImages";
import { AuthContext } from "../Firebase/AuthProvider";
import { colors } from "@mui/material";
import Order_status from "./order_status";

import rarr from '../assets/icon/Rarrow.png'

import rarr_exp from '../assets/icon/arrow_exp_left.svg'

import { fabrics, StylesFilter } from '../Data';


import insta from '../assets/icon/social/instagram_c.png'
import fb from '../assets/icon/social/facebook_c.png'
import pin from '../assets/icon/social/pinterest_c.png'
import twitter from '../assets/icon/social/twitter_c.png'
import yt from '../assets/icon/social/youtube_c.png'
import whatsapp from '../assets/icon/social/whatsapp_c.png'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const orderStatus = ["Order Received", "Order Confirmed", "Order Processing", "In Transit", "Order Delivered"]




const measure_profile = {
    "u":[ 
    {"value":"length","name":"Length"},
    {"value":"chest_around","name":"Chest"},
    {"value":"stomach","name":"Stomach"},
    {"value":"hip","name":"Hip"},
    {"value":"shoulder_width","name":"Shoulder"},
    {"value":"sleeve_length","name":"Sleev Length"},
    {"value":"neck","name":"Neck"},
    {"value":"bisep","name":"Bisep"}],
    "l":[
        {"value":"leg_length","name":"Leg Length"},
        {"value":"waist","name":"Waist"},
        {"value":"bottom","name":"Bottom"},
        {"value":"hip","name":"Hip"},
        {"value":"thigh","name":"Thighs"},
        {"value":"circle","name":"Circle"}
    ],
}

const order_help = [
    { title: "Where is My Order ?" },
    { title: "Cancel My Order" },
    { title: "Fitting issues ? Get Altered" },
    { title: "Talk to us" },
    { title: "Give a Review" }
]

function Profile() {
    // const [User, setUser] = useState();

    const { user, running, userSignOut} = useContext(AuthContext);

    const navigate = useNavigate()
    const [Orders, setOrders] = useState()

    const [loading, setLoading] = useState(false)
    const [imgState, setImgState]=  useState(0)
    const [State, setState] = useState(1)
    const [modalFab, setModalFab] = useState()
    
    const [help, setHelp] = useState({ id:-2 })

    const Wishlist = []
    const Adddress = []
    const Measurement = []

    const profile = ["Wishlist", "Order History","Shopping Cart", "Saved Addresses", "Measurements Profile", "Log out"]

    const [current, setCurrent] = useState(profile[1])
    

    const [modal, setModal] = useState(0)
    const [order, setOrder] = useState();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
    const [menuOpen, setMenuOpen] = useState(false);

    const [currH, setCurrH] = useState()

    const sidebarRef = useRef(null);
    const overlayRef = useRef(null);

    const [social, setSocial] = useState([{"src":insta, "link":"https://www.instagram.com/maviinci.in/"},
        {"src":fb, "link":"https://www.facebook.com/people/maviinciin/61556379716484/"},
        {"src":pin, "link":"https://in.pinterest.com/maviincifashion/"},
        {"src":twitter, "link":"https://x.com/maviincifashion?t=3Hy6jKr1NxuBUemXqMeYUA&s=08"}, 
        {"src":yt, "link":"https://www.youtube.com/@MAVIINCI_FASHION"}, 
        {"src":whatsapp, "link":"https://wa.me/918871081395"}, 
        ] ) 

    const getFabDetails = async(Ord)=>{
        try{
            const Styles = getStyleDetails()

            // console.log("Styles", Styles)

            var Fabric = {}
            const collection = db.collection('Fabric').doc('Suits').collection("fabrics")

            
            if( !Object.keys(Ord).includes("item_type") ){

                const types = Object.keys(Ord.fabric)
                for(let j=0; j<types.length;j++ ){
                    const data = await getDocOnCondition(collection, "id", Ord.fabric[types[j]].fabricID);
                    Fabric[types[j]] = data[0]
                }
                
            } else {
                if(Ord.item_type === 1){
                    for(let j=0;j<Ord.fabrics.length;j++){
                        Fabric[Ord.fabrics[j].type] = {
                            brand : Ord.fabrics[j].brand,
                            brandImg : Ord.fabrics[j].brandImg,
                            color : Ord.fabrics[j].color,
                            material : Ord.fabrics[j].material,
                            pattern : Ord.fabrics[j].patter,
                            weave : Ord.fabrics[j].weave,
                            width : Ord.fabrics[j].width,
                            src: Ord.fabrics[j].src,
                            name : Ord.fabrics[j].name
                        }
                    }
                } else {
                    Fabric["Fabric"] = {
                        brand : Ord.brand,
                        brandImg : Ord.brandImg,
                        color : Ord.color,
                        material : Ord.material,
                        pattern : Ord.patter,
                        weave : Ord.weave,
                        width : Ord.width,
                        src: Ord.src,
                        name : Ord.name
                    }
                }
                
                
            }

            // setOrder({...order, ...{"Fab":Fabric}})
            setModalFab(Fabric)
            // console.log({...order, ...{"Fab":Fabric}})
            let c = 0;
            for(let i = 0;i<Orders.length;i++){
                if(Orders[i].id === order.id){
                    c = i;
                    break;
                }
            }
            var temp = [...Orders]
            temp[c] = {...order, ...{"Fab":Fabric}, ...{"style_des":Styles}}
            setOrder({...order, ...{"Fab":Fabric}, ...{"style_des":Styles}})
            setOrders(temp)
            setCurrH( Object.keys(Fabric)[0] )

        }catch(e){
            console.log(e)
        }
    }
    useEffect(()=>{
        if(order){
            if( !order["Fab"] ){
                getFabDetails(order).then(()=>{

                }).catch((e)=>{console.log(e)})
            } else {
                setModalFab(order["Fab"])
            }
        
        }
    }, [order])

    useEffect(()=>{
        console.log("Modal order :", order)
    }, [modal])
    // const User = useMemo(()=> getUser());
    const getOrders = async () => {
        try{
            const collection = db.collection("Orders")
            let usrid = user.email
            if(!usrid){
                usrid = user.phoneNumber
            }
            const orders = await getDocOnCondition(collection, "email", usrid)
            // console.log("Orders ", orders)
            return orders
        }catch(e){  
            // console.log("from fetching the order", e )
        }
    }

    useEffect(()=>{
        if(current === profile[1]){
            getOrders().then((data)=>{setOrders(data)}).catch((e)=>{console.log("from orders. ", e)})
        }
    }, [current])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const getStyleDetails = ()=>{

        var StylesDets = {}
        // console.log("coming here in Styles des funct")
        if(order && order.style){
                
            Object.keys(order["style"]).forEach((t)=>{
                StylesDets[t] = []
                Object.keys(order["style"][t]).forEach((side)=>{
                    
                    var value = null;
                    var img = null;
                    order["style"][t][side].forEach((val, k)=>{
                        if((t!=="Shirt") || (t === "Shirt" && val!= "BASE" && val!="SLEVE")){
                        
                        var r = null
                        
                        if(t === "Coat" && side === "F" && val[0] === "J"){
                            r = StylesFilter.find((filter) => filter.type === "Jodhpuri");
                            console.log("r:", r)
                        } else{
                            r = StylesFilter.find((filter) => filter.name === t && filter.pos === side && filter.n === k);
                        }
                        
                        
                        // console.log(r, side, t)
                            
                        // console.log("from inside", r["attr"])
                        for(let i = 0;i<r["attr"].length;i++){
                            if(value){break}
                            if( Array.isArray(r["attr"][i])){
                                for(let j = 0;j<r["attr"][i].length;j++){
                                    // console.log("of a type:", r["attr"][i][j])
                                    if(r["attr"][i][j]["value"] === val){
                                        value = r["attr"][i][j]["name"]
                                        img = r["attr"][i][j]["img"]
                                        break    
                                    }
                                }
                            }else{
                                if(r["attr"][i]["value"] === val){
                                    value = r["attr"][i]["name"]
                                    img = r["attr"][i]["img"]
                                    break
                                }
                            }
                        }
                        
                        if(value){
                            StylesDets[t].push( {"type":r["type"], "name":value, "img":img})
                        }
                         
                        //  `${r["type"]} : ${value}`
                        value = null
                        }
                    })
                })
            })

            return StylesDets
            // console.log("From styles Description :", StylesDets)
                
            // StylesFilter
        }   
    }
    return ( 
    <div className="Profile">
        <div>
            <NavBar  {...{flag:2}}/>
            <div style={{"height":"80px"}}></div>
        </div>
        {modal === 1 && <>
        <div className="oder_opac"></div>
        <div className="order_modal both_center">
            <img src={close} className="o-m-close"
                type="button" onClick={()=>{
                    setImgState(0)
                    setModal(0)
                    setModalFab(null)
                    setOrder(null)
                }}
            />
            <div className="order_modal_img">

            {!Object.keys(order).includes("item_type") && <><img src={isMobile ? nextArr1 : nextArr} className='v-arr-l'
                            onClick={()=>{
                                if(imgState > 0){
                                    // console.log("Clicking")
                                    setLoading(true)
                                }
                                setImgState((curr)=>(
                                curr > 0 ? curr - 1 : 0
                            ))}}
                        />
                    <img src={isMobile ? prevArr1 : prevArr} className='v-arr-r'
                            onClick={()=>{
                                if(imgState < State-1){
                                    // console.log("clicking")
                                    setLoading(true)
                                    setImgState((curr)=>(
                                        curr < State ? curr + 1 : curr
                                    ))
                                }
                                
                                
                        }}
                            
                        />
                        </>
                }
                {order && 
                <>
                {!Object.keys(order).includes("item_type") ? 
                    <ViewImages
                    sel = {order}
                    loading = {loading}
                    setLoading={setLoading}
                    imgState={imgState}
                    setState1 = {setState}
                    setImgState={setImgState}
                    type = {1}
                />
                
                : <img src={order.src}  className="modal_order_img" />
                }
                </>
                }

                        
            </div>
            <div className="order_modal_wrapper">
            <div className="order_modal_des">
                <div className="txt-font">{order.name}</div>
                <div>Order ID : {order.id}</div>
                <div>Item Includes :  { modalFab && Object.keys(modalFab).map((val1)=>{return(<span>{val1},</span>)})   } </div>
                {/* <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div> */}
                <br/>
                <div className="txt-font1">Total : 
                { Object.keys(order).includes("item_type") && order.item_type === 0 ? 
                <>
                    {Number(250).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                        })}</>
                    :
                
                    <>{Number(order.price).toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                    })}</>
                    }
                    {/* { Number(order.price).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    }) } */}
                </div>
                <div className="txt-font1">Status : {order.status ? orderStatus[order.status.status] :"Order Received"}</div>
                <br/><Order_status status={ order.status ? order.status.status : 0  }/>
                <br/><br/>
                <div className="order_Address"> 
                    <div className="o-d-add">
                        <b>Delivery Adddress  </b>
                        <div>{order.Address.name}</div>
                        <div>{order.Address.phone}</div>
                        <div>{order.Address.address}</div>
                        <div>{order.Address.city}</div>
                        <div>{order.Address.state} | <div>{order.Address.pincode}</div></div>

                    </div>
                    <div className="o-b-add">
                        <b>Billing Adddress  </b>
                        <div>{order.Address.name}</div>
                        <div>{order.Address.phone}</div>
                        <div>{order.Address.address}</div>
                        <div>{order.Address.city}</div>
                        <div>{order.Address.state} | <div>{order.Address.pincode}</div></div>
                    </div>
                </div>
                <br/>
                <div className="txt-font">Fabric Details</div>
                <div>
                    {order && modalFab && 
                    <div className='preset_fabric_details'>
                        <div className='p_f_d_t txt-font1'
                            style={{width:"100%"}}
                        >
                            {Object.keys(modalFab).map((val1)=>{return(<div className={ currH === val1 ? "h_p_h p_h_i" : "p_h_i" } 
                                style={{width:`${100/Object.keys(modalFab).length}%`, border:"1px solid #EAEAEA"}}
                                onClick={()=>{
                                    setCurrH(val1)
                                }}
                                type="button"
                            >{val1}</div>)})}
                        </div>
    
                        <div style={{display:"flex"}}>
                            <div style={{"width":"45%", marginRight:"5%"}}><img src={modalFab[currH]["src"]} className='p_f_d_img'/></div>
                            <div style={{"width":"50%", display:"flex", flexDirection:"column"}}>
                                <b><span>{modalFab[currH].name}</span></b>
                                <span> <img src={modalFab[currH].brandImg} style={{"width":"80px"}}/> {modalFab[currH].brand}</span>
                                <span>{modalFab[currH].material}</span>
                                <span>Weave : {modalFab[currH].weave}</span>
                                <span>Weight : {modalFab[currH].weight}</span>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <br/>
                
                {order && order["style_des"] && 
                <div>
                <div className="txt-font"
                    
                > 
                    Style Selection </div>

                    {/* <div className='p_f_d_t txt-font1'
                            style={{width:"100%"}}
                        >
                            {Object.keys(order["style_des"]).map((val1)=>{
                                return(<div className={ currH === val1 ? "h_p_h p_h_i" : "p_h_i" } 
                                style={{width:`${100/Object.keys(order["style_des"]).length}%`, border:"1px solid #EAEAEA"}}
                                onClick={()=>{
                                    setCurrH(val1)
                                }}
                                type="button"
                            >{val1}</div>)})}
                        </div> */}

                        <div>
                            <div style={{"width":`${order["style_des"][currH].length*25}%`, "display":"flex", "position":"relative"}}
                                className='hori_center'

                            >
                                { order["style_des"] && order["style_des"][currH].map((sty)=>{
                                    return(
                                        <div className='single_s_des'
                                            style={{"width":`${100/order["style_des"][currH].length}%`}}
                                        >
                                            <img src={sty.img} className='hori_center' />
                                            <b><label>{sty.type}</label></b>
                                            <label>{sty.name}</label>
                                            
                                        </div>
                                    )
                                })
                                }
                            </div>
                            
                        </div>
                    

                </div>
                }
                
                <br/>
                {/* Order Modal Measurement */}
                <div>
                <div className="txt-font1"
                    style={{textAlign:"center", backgroundColor:"#14213D", color:"white", borderRadius:"5px"}}
                > 
                    {order.measurements.type === 0 ? "Measurement - Size Chart" : "Custom Measurement"}</div>

                <br/>
                
                {order.measurements.type === 1 ? 
                <div style={{
                    width:"100%",
                    display:"flex",
                    gap:"10px"
                }} >
                    <div className="order_m_m_l"
                        style={{width:"50%",
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >
                        <b>Upper Body</b>
                        {measure_profile.u.map((val)=>{
                            return(<label>{val["name"]} : <b>{order.measurements.upper_body[val["value"]]}</b> IN </label>)
                        })}
                        
                    </div>
                    <div className="order_m_m_r"
                        style={{width:"50%",
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >   
                        <b>Lower Body</b>
                        {measure_profile.l.map((val)=>{
                            return(<label>{val["name"]} : <b>{order.measurements.lower_body[val["value"]]}</b> IN </label>)
                        })}
                    </div>
                </div>
                : <div>

                    <label> <b> Upper Body : </b> {order.measurements.upper_body} IN </label><br/>
                    <label> <b>Lower Body : </b> {order.measurements.lower_body} IN </label><br/>
                    
                </div>

                }

                </div>
                
            </div>
            </div>
        </div>
        </>}
        {isMobile && (
            <>
                    <div className="burger-menu21" onClick={() => setMenuOpen(!menuOpen)}>
                        <span className="burger-menu-icon3">&#9776;</span>
                    </div>
                    
                    </>
                    
                )}
        <div className="profile_body">
        
            <div className={`profile-b-left ${isMobile ? (menuOpen ? 'show' : 'hidden') : ''}`} >
            {isMobile && (
                <span className="close-sidebar" onClick={() => setMenuOpen(false)}>&times;</span>
            )}
                <div className="profile-card">
                    <div className="Circle"> <label className="both_center title-font1"><b>
                        {user && user.displayName ? user.displayName.split(" ")[0][0]:""}
                    </b></label> </div>
                    <div className="txt-font">
                        {user ? user.displayName.split("@gmail.com")[0] :""}
                    </div>
                    <div>{user && user.email? 
                            user.email :
                            user &&  user.phoneNumber ? 
                            user.phoneNumber  : "" }</div>
                    
                </div>
                <div className="profile-list">
                    {profile.map((value, key)=>{
                        return(

                        <div className="profile-l-e txt-font1"
                            style={value === current ? {"opacity":"1"} : {"opacity":"0.7"}}
                            onClick={()=>{
                                if(value === profile[profile.length - 1]){
                                    logout().then(()=>{
                                        navigate("/")                    
                                    }).catch(()=>{})                
                                }
                                else if(key === 2){
                                    navigate("/cart")
                                }
                                else{
                                    setCurrent(value)    
                                }
                            }}
                            type="button"
                        >
                            {value}
                        </div>)
                    })}
                </div>
            </div>
            <div className="profile-b-right">
                <div className="txt-font">
                    {current}
                    <hr/>
                </div>
                {current === profile[0] ? 
                    <>
                        {Wishlist && Wishlist.length > 0 ? <></> :<>Nothing in here yet</>}
                    </> 
                    : current === profile[1] ? 
                    <>
                        {Orders && Orders.length > 0 ? 
                        <>
                            {Orders.map((value, key)=>{
                                return(
                                    <>
                                    {/* {true || !Object.keys(value).includes("item_type") &&  */}
                                    <Order_card 
                                        order = {value}
                                        setOrder = {setOrder}
                                        setModal = {setModal}
                                        setHelp = {setHelp}
                                    />
                                    {/* } */}
                                    </>
                                )
                            })}
                        </> : <>Nothing in here yet</>}
                    </> 
                    : current === profile[3] ? 
                    <>
                        {Adddress && Adddress.length > 0 ? 
                        <div className="saved_address">   
                            <div className="s_a_box">

                            </div>
                        </div> :<>Nothing in here yet</>}
                    </> 
                    : current === profile[4] ? 
                    <>
                        {Measurement && Measurement.length > 0 ? <></> :<>Nothing in here yet</>}
                    </> : <></>
                }
                
            </div>
        </div>

        
        {/* <div className="title-font">
            <br/><br/>
            Hi
            Profile Page + Previous Orders
            <button
                onClick={()=>{
                    
                }}
            >Logout</button>
        </div> */}

        { help && help.id !=-2 && 
        <div className='order_help_wrapper'>
            <div className='order_help both_center'>

                { help.id !=-1 && <a href="https://maviinci.in/terms" style={{ position:"absolute", bottom:"5px" }} className="FIT_W hori_center"  > Privacy Policy  </a>}
                
                <img src={close} style={{width:"25px", position:"absolute", right:"2%"}} onClick={()=>{setHelp({id:-2})}} type="button"/>
                <div className='order_h_header'>
                    <div className='txt-font' > Suport </div>
                    <div style={{fontSize:"1rem"}} > Order ID : # {help.orderId}  </div>
                </div>
                
                
                <div className='order_help_body'>
                        { help.id >= 0 && 
                            <div style={{width:"100%", position:"relative", marginTop:"10px"}}>
                                <img src={rarr_exp} className="order_help_left verti_center" onClick={()=>{ setHelp({...help, ...{"id":-1}}) }} type="button"/>
                                <div style={{textAlign:"center", textDecoration:"underline"}} className="txt-font" > { order_help[help["id"]].title } </div> 
                            </div>
                        }

                    {!help || (help && help.id === -1 ) ?
                    
                    <>
                        <label className='FIT_W hori_center order_help_des' style={{textAlign:"center", marginTop:"15px"}}>We are happy to assist you with the order. Select your issue from below or talk to us.Please read through our Privacy Policy if you have any questions. We value your feedback, please leave a review if you liked the item :)</label>
                        {order_help.map((val, ind)=>{
                            return(
                                <div className='order_help_elements hori_center'
                                    onClick={()=>{
                                        setHelp( {...help, ...{"id":ind } } )
                                        console.log({...help, ...{"id":ind } })
                                    }}
                                    type="button"
                                >
                                    {val.title}
                                    
                                    <img src={rarr}/>
                                </div>
                            )   
                        })}
                    </>
                    : help.id === 0 ?
                    <>
                        
                        
                        <div className="order_track_help FIT_W hori_center" style={{height:"fit-content", marginTop:"40px"}} >
                            Order ID :# &nbsp; { help.orderId ? help.orderId : "" }
                            <br/>Last Updated :  { !Object.hasOwn(help.order,"status") ? 
                                            <>{help.date[2]}, {months[help.date[1]]} {help.date[0]}</>
                                        : help.statusDate ? <>{help.statusDate[2]}, {months[help.statusDate[1]]} {help.statusDate[0]}</> : ""
                                    }

                            <div className='FIT_W'>
                                    <br/><b>{Object.hasOwn(help.order,"status") ? `Status : ${orderStatus[help.order.status.status]}`:`Status : ${orderStatus[0]}`}</b>
                                </div>
                                
                                <>
                                    <br/>

                                    Order Tracking 
                                    <br/>
                                    <Order_status status={ help.order.status ? help.order.status.status : 0  }/> 
                                </>
                        </div>


                    </>

                    : help.id === 1 ? <></>
                    : help.id === 2 ? <></>
                    : help.id === 3 ? 
                    <>


                        <div style={{width:"fit-content", height:"fit-content"}} className="hori_center">
                            <br/>
                            <table>
                                <tr>
                                    <td><b>Email </b></td>
                                    <td>isupport@bluepearlindustries.com</td>
                                </tr>
                                <tr>
                                    <td> <b>phone</b></td>
                                    <td>+91-8871081395</td>
                                </tr>
                            </table>
                            <br/>
                            <div className='' style={{textAlign:"center"}}>
                                Address : <br/>
                                Shanti Nagar Bhilai-3<br/>
                                Dist-durg chhattisgarh <br/>
                                Pin 490021<br/>
                            </div>
                            
                            
                            <div style={{width:"100%", textAlign:"center"}}>
                                <a href="hhttps://maviinci.in/contact" className="FIT_W " > Contact Us </a>
                            </div>
                            

                        </div>


                    </>
                    : <></>
                    }
                </div>


            </div>
        </div>
        }
    </div> );
}

export default Profile;