import acc from './assets/acc.svg'

import logo from './assets/logo_side.png'

import logo_m from './assets/logo_m.svg'
import { useNavigate } from 'react-router-dom'

import arr from './assets/icon/Rarrow.png'
import { useContext, useState } from 'react'

import close from './assets/icon/close_no_bg.png'
import { AuthContext } from './Firebase/AuthProvider'

const navs = [
    
    {attr: "Custom Clothing", nav:"/custom" },
    {attr: "Designer's Exclusive", nav:"/presets" },
    {attr: "Feel Fabric", nav:"/feel" },
    {attr: "Get Quatation", nav:"" },
    {attr: "Request Exhibhition", nav:"" },
    {attr: "About US", nav:"/" },
    {attr: "Contact Us", nav:"/contact" },
]

function SidebarNav({sidebar, setSideBar}) {

    const {user} = useContext( AuthContext )

    const navigate = useNavigate()

    const [Style, setStyle] = useState({ "opacity":"0"})

    return ( 
    
    
    <div className={`nav_sidebar ${sidebar? 'nav_sidebar_open' : 'nav_sidebar_close'}`}
            // style={sidebar ? {} : {transform: "translateX(-100%)"} }
    >
        <img src={close} 
            onClick={()=>{setSideBar(false)}}
            style={{width:"20px", position:"absolute", top:"10px", right:"10px"}}
            className='close_sidebar'
        type="button"/>

        <div className='sidebar_header'> 
            
            <div className='logo_wrap'>
            <a href="/" style={{textDecoration:"none"}}  >
                <img src={ logo_m } onClick={()=>{setSideBar(false)}} type="button"/>
            </a>
            </div>
        </div>

        <div className='sidebar_body'>
            {navs && navs.map((val, ind)=>{
                return(
                    <a href={val.nav} style={{textDecoration:"none", color:"black"}}>
                    <div className='side_bar_nav_body hori_center txt-font'
                        onClick={()=> {setSideBar(false)} } type = "button"
                        onMouseEnter={()=>{
                            setStyle({
                                transform:"rotate(0)",
                                "opacity":"0.8",
                                "id":ind
                            })
                        }}
                        onMouseLeave={()=>{
                            setStyle(
                                {
                                    transform:"rotate(90deg)",
                                    "opacity":"0",
                                    "id":ind
                                }
                            )
                        }}
                    >
                        <img src={arr} className='verti_center' style={ Style["id"] === ind ? Style :{} } />
                        { val.attr }
                    </div>
                    </a>
                )
            })

            }
        </div>  

        <div className="sidebar_acc">

        
            <img src={acc} style={{width:"20%", objectFit:"contain"}}
                onClick={()=>{navigate('/profile');}}
            />
            
            <div className='txt-font' > 
                <label className='FIT_H FIT_W verti_center' > {!user ? 'Login': user.displayName ? (user.displayName.split(" ")[0]).split("@gmail.com")[0] : user.phoneNumber } </label> </div>

        </div>
    </div> 
     
        
    
    );
}

export default SidebarNav;