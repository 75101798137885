import { useContext, useEffect, useState } from "react";
import Cart from "./cart";
import './checkout.css'
import Cost from "./cost_details";
import Address from "./address";
import { db } from "../firebase";

import aim from '../assets/samples/anima.gif'

import DEL from '../assets/icon/del.png'

import close from '../assets/icon/close.png'

import measure from '../assets/samples/TAPE.png'

import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../Firebase/AuthProvider";
import Done from "./done";
import Terms from "../terms";



function Checkout() {

    const { user } = useContext(AuthContext);

    let { id } = useParams();

    const navigate = useNavigate()
    const [page, setPage] = useState( id!= null ? 1 : 0  )
    const [change, setChange] = useState(0)

    // const [checkoutData, setcheck] = useState({})

    // const [payment, paymentStatus] = useState({"id": -100});

    // const [order,setOrder] = useState()

    const [modal_item, setModalItem] = useState({"s":false})

    const [cartItems, setCart] = useState(JSON.parse(localStorage.getItem("sel")))

    const [terms, setTerms] = useState(false)

    const [ inCart, setInCart ] = useState()

    const [loading, setLoading] = useState(false)


    

    function deleteFromCart(id, type = 0){
        // console.log("id received", id)
        let data = JSON.parse(localStorage.getItem("sel"))
        console.log("deleting", id);

        
        for(let i=0;i<data.length;i++){
            
            if(type === 0){

                if(data[i].id == id){
                    
                    if(data.length == 1){data = []}else{data.splice(i, 1)}
                    // console.log("After delete", data)
                    setInCart(data)
                    localStorage.setItem("sel", JSON.stringify(data))
                    setCart(data)
                    setChange((prev)=>!prev)
                    return true
                }
            } else {
                if(data[i].item_type === 0){
                    
                    let del_all = 0;
                    if(data[i].items.length == 1 && data.length === 1){
                        data = []
                    }else{
                        if(data[i].items.length == 1){
                            data.splice(i, 1)
                            del_all = 1;
                        } else {
                            for(let j=0;j<data[i].items.length;j++){
                                if(data[i].items[j].id === id){
                                    data[i].items.splice(j, 1)
                                }
                            }
                        }
                    }
                    console.log("After Data changes ", data)    
                    // console.log("After delete", data)
                    setInCart(data)
                    localStorage.setItem("sel", JSON.stringify(data))
                    setCart(data)
                    if(del_all === 1){
                        setModalItem({"s":false})
                    } else {

                    
                    setModalItem({...{
                        "s":"true"
                    }, ...data[i]})
                    }
                    setChange((prev)=>!prev)
                    return true
                }
                
            }
        }
        
        return false
    }

    // useEffect(()=>{
        
    //     if(payment["id"] === 0 && payment["status"] === true){
    //         postOrder().then(()=>{})
    //     }else if(payment["id"] === 0 && payment["status"] === false){
    //         paymentStatus({
    //             "id": -1
    //         })
    //     }

    // }, [payment])

    useEffect(()=>{
        let Data = JSON.parse(localStorage.getItem("sel"))
        let p = 0;
        let fab = 0;
        if(Data){
        
            for(let i=0;i<Data.length;i++){
                if(! (Object.keys(Data[i]).includes("item_type") && Data[i].item_type === 0) ){

                    if( !Object.keys(Data[i]).includes("item_type") ){
                        p= p + Number(Data[i].price) - Number(Data[i].discount)
                    } else {
                        p = p+ Number(Data[i].price) - (Number(Data[i].price) * Number(Data[i].discount)/100)
                    }
                    

                }else{
                    fab=  fab + Data[i].items.length
                }
            }
        p = Number(p)

        let extra = 0;
        if(fab>1){
            extra = 250
        }else{
            extra = (fab*100)
        }

        // setcheck({"price": p + Number(extra) })
        } else {
            // setcheck({"price":0+0.12*Number(0)})
        }
        


    }, [cartItems])



    return ( 
    <div className="Checkout">

        <div className="check_nav hori_center">
            
                <div className="check_nav_item" 
                    style={page === 0 ? {borderBottom:"2px solid #00a31b", color:"#00a31b"}: {}} > 
                    <label className="FIT_H verti_center" > Cart </label> </div>
                <div className="check_nav_item" > <hr/>  </div>
                <div className="check_nav_item" 
                    style={page != 0 ? {borderBottom:"2px solid #00a31b", color:"#00a31b"}: {}} 
                > <label className="FIT_H verti_center" > Address </label> </div>
                <div className="check_nav_item" > <hr/>  </div>
                <div className="check_nav_item" > <label className="FIT_H verti_center" > Payment </label> </div>
            
        </div>

        {loading && <div style={{"position":"fixed", "height":"100vh", "width":"100vw", "background":"rgb(0, 0, 0, 0.3)", "textAlign":"center", "top":"0", "left":"0", "zIndex":"1000"}}>
                <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'" 
                    style={{"zIndex":"1", "top":"40vh", "position":"absolute"}}
                    className="hori_center"
                />
                
            </div>}
        {page === 0 ? (
            <div className="cart-page">
                <Cart
                    setChange = {setChange}
                    modal_item = {modal_item}
                    setModalItem = {setModalItem}
                    cartItems = {cartItems} 
                    setCart = {setCart}
                    deleteFromCart = {deleteFromCart}
                    inCart = {inCart} 
                    setInCart = {setInCart}
                />
                <Cost
                    change = {change}
                    setChange = {setChange}

                    page = {page}
                    setPage= {setPage}

                    pay = {-1}
                    setTerms = {setTerms}
                />
            </div>
        ) 
        : (<div className="cart-page">
            <Address
                setPage ={setPage}

                // setOrder = {setOrder}
                loading = {loading}
                setLoading = {setLoading}
            />
            <Cost
                change = {change}
                setChange = {setChange}

                pay = {-1}
            />
    </div>) 
    }
    

    {((modal_item && modal_item["s"]) || terms) && <div className="opac_cart"
        onClick={()=>{
            setModalItem({"s":false})
            setTerms(false)
        }}
    ></div>}
    {modal_item && modal_item["s"] && <div className="fabric_cart_modal both_center">
        <div  className="f_c_m_W" style={{position:"relative", width:"100%", height:"100%"}}>
        
        <div className="txt-font1 cart_f_modal">
            <label className="FIT_W hori_center">Fabric Samples</label>
            <img src={close} style={{position:"absolute", "right":"0"}}
              onClick={()=>{setModalItem({"s":false})}}
            />
        </div>
        <br/>
        <div className="hori_center"
            style={{width:"80%", textAlign:"center"}}
        > 
            <div style={{display:"flex"}}>
                
                <label
                    style={{"width":"calc(100%-110px)"}} className="mob_f_des"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</label>
                <img src={measure} style={{width:"100px"}}/>

            </div>

            <div style={{position:"relative"}}>
                <span><b>Fabrics { modal_item.items.length } /5 </b> </span>
                <span
                    style={{position:"absolute", "right":"0"}}
                >Total : { modal_item.items.length === 1 ? 100 : 250}</span> 
            </div>
            
        </div>

        <br/>


        <div className="fabric_cards_wrapper">
        <div className="hori_center fabrics_wrapper">
            {modal_item && modal_item["s"] && modal_item.items.map((val, ind)=>{
                return(
                    <div className='preset_fabric_details hori_center'>

                    
                    
                    

                    <div className='fab_p' style={{display:"flex"}}>
                        <img src={DEL} style={{width:"25px", position:"absolute", top:"5px", right:"5px"}}

                            onClick={()=>{
                                deleteFromCart(val.id, 1)
                            }}
                            type="button"
                        />

                        <div style={{"width":"35%", marginRight:"5%"}}><img src={
                             val["single"] && val["single"].length>0 ?val["single"][0] :val["src"]
                            } className='p_f_d_img'/></div>
                        <div style={{"width":"65%", display:"flex", flexDirection:"column"}}>
                            
                            <div className='FIT_H both_center'
                                style={{display:"flex", flexDirection:"column"}}
                            >
                            <div style={{display:"flex"}}>
                                <img src={val.brandImg} style={{"width":"80px"}}/>
                                <span className='p_fab_n'>{val.name}</span>
                            </div>
                            
                            <span>{val.material}</span>
                            <span>Pattern : {val.pattern}</span>
                            <span>Weave : {val.weave}</span>
                            <span>Width : {val.width}</span>
                            <span style={{fontSize:"0.6rem"}}> {val.des} </span>
                            </div>

                        </div>                        
                    </div>
                    
                </div>
                )
            })

            }
               </div>
            </div>
        </div>
    </div>}

    {terms && 
    <div className="terms_modal both_center">
        <div><b>Terms and Conditions</b></div><br/>
        <div style={{height:"65vh", overflowX:"hidden", "overflowY":"scroll"}}>
            <Terms/>
        </div>
    </div>

    }
        
    </div> );
}

export default Checkout;