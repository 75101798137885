
import { useState } from 'react';
import Logo from './assets/logo_name_white.png'
import razorpay from './assets/logo/razorpay_w.svg'

import insta from './assets/icon/social/instagram.png'
import fb from './assets/icon/social/fb.png'
import pin from './assets/icon/social/pinterest.png'
import twitter from './assets/icon/social/twitter.png'
import yt from './assets/icon/social/youtube.png'
import whatsapp from './assets/icon/social/whatsapp.png'
import { useNavigate } from 'react-router-dom';


import cod from './assets/logo/cod.svg'
import fit from './assets/logo/fitt.svg'
import free from './assets/logo/free_delivery.svg'
import quality from './assets/logo/quality.svg'
import cust from './assets/logo/customization.svg'

export default function Foot(){

    const navigate = useNavigate()

    
    const [social, setSocial] = useState([{"src":insta, "link":"https://www.instagram.com/maviinci.in/"},
        {"src":fb, "link":"https://www.facebook.com/people/maviinciin/61556379716484/"},
        {"src":pin, "link":"https://in.pinterest.com/maviincifashion/"},
        {"src":twitter, "link":"https://x.com/maviincifashion?t=3Hy6jKr1NxuBUemXqMeYUA&s=08"}, 
        {"src":yt, "link":"https://www.youtube.com/@MAVIINCI_FASHION"}, 
        {"src":whatsapp, "link":"https://wa.me/918871081395"}, 
        ] ) 
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    const [ label, setLabel ] = useState([
        { img:quality, des:"High Quality" },
        { img:cust, des:"Customization" },
        { img:fit, des:"Perfect Fit" },
        { img:free, des:"Free Delivery" },
        { img:cod, des:"Cash on Delivery" }
    ])


    // img 150 
    // bottom most text - 0.8rem 

    return(
        <footer>
        <div className="foot1">

            <div className='footer_label'>

                <div style={{display:"flex", gap:"3%"}}>
                    {label.map((val)=>{
                        return(
                            <div className='f_l_ele_card'>
                        
                                <div className='f_l_ele_img' > <img src={val.img}/> </div>
                                
                                <label> {val.des} </label>
                            </div>
                        )
                    })

                    }
                    

                </div>

            </div>
            
            {!isMobile && <div style={{position:"absolute", bottom:"2%", left:"2%"}}>© COPYRIGHT BLUE PEARL INDUSTRIES</div>}
            <div className='foot_containter'>

                <div className='foot_first'>
                    <img src={Logo} className='hori_center'/>
                    <div className="foot_tag hori_center" >"Where fashion meets the future"</div>
                    <div className='foot_des hori_center'>
                    If you're looking for custom tailoring with exceptional quality and fine workmanship, MAVIINCI is the perfect place for you. They specialize in creating bespoke clothing that reflects your personal style while ensuring a perfect fit. With a commitment to using premium fabrics and meticulous attention to detail, MAVIINCI delivers garments that stand out for their craftsmanship and elegance. Whether you need a tailored suit, a custom dress, or any other piece, you can expect outstanding results. Have you considered any specific styles or pieces you’d like to explore?
                    </div>
                </div>

                <div className='foot_second'>
                    <div style={{fontSize:"1.5rem", marginBottom:"10px", textDecoration:"underline"}} > Custom Tailor </div>
                    <ul>
                        <li> Suit </li>
                        <li> Formal Wear </li>
                        <li> Blazer </li>
                        <li> Kurta </li>
                        <li> Uniform </li>
                        <li> Shirt </li>
                        <li> Pant </li>
                        <li> Jubba </li>
                        <li> T-Shirt </li>
                    </ul>
                </div>

                <div className='foot_third'>
                    <div style={{fontSize:"1.5rem", marginBottom:"10px", textDecoration:"underline"}} > Quick Links </div>
                    <ul>
                        <li> Designer / Exclusive </li>
                        <li> Feel the Fabric </li>
                        <li> Exibition </li>
                        <li> Blog </li>
                        <li> About Us </li>
                    </ul>
                </div>

                <div className='foot_forth'>
                    <div style={{fontSize:"1.5rem", marginBottom:"10px", textDecoration:"underline"}} > Customer Service </div>
                    <ul>
                        <li> Terms and Conditions </li>
                        <li> Privacy Policy </li>
                        <li> FAQ </li>
                        <li> Contact Us </li>
                        <li> Track your Order </li>
                    </ul>

                    <div style={{fontSize:"1.5rem", textDecoration:"underline"}} > Blue Pearl Industries </div>
                    Shanti Nagar Bhilai 3<br/>
                    Dist-Durg (C.G) Pin-490021<br/>
                    Email : Connect@bluepearlindustries.com<br/>
                    Phone-0788-4069527
                </div>


                <div className='mobile_links hori_center'>

                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            CUSTOM TAILOR
                        </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <ul>
                                <li> Suit </li>
                                <li> Formal Wear </li>
                                <li> Blazer </li>
                                <li> Kurta </li>
                                <li> Uniform </li>
                                <li> Shirt </li>
                                <li> Pant </li>
                                <li> Jubba </li>
                                <li> T-Shirt </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            QUICK LINKS
                        </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            <ul>
                                <li> Designer / Exclusive </li>
                                <li> Feel the Fabric </li>
                                <li> Exibition </li>
                                <li> Blog </li>
                                <li> About Us </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            CUSTOMER SERVICE
                        </button>
                        </h2>
                        <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                            
                            </div>
                        </div>
                    </div>
                </div>

                </div>
            </div>

            <div className='foot_end_wrap' >
            <div className='foot_end'>
                <div style={{display:"flex", flexDirection:"column"}} className={`FIT_W ${isMobile? 'hori_center':''} `}>
                    <label style={{fontSize:"0.7rem"}} > Our Payment Partner </label>
                    <img  src={razorpay} className='foot_razor'/>
                </div>

                <div className={`social ${isMobile? 'hori_center':''} `} >
                        {social && social.map((val, key)=>{
                            return(
                                <a href={val.link}><img src={val["src"]} className='social_l'/></a>
                            )
                        })}
                </div>

                {isMobile && 
                    
                    <div style={{textAlign:"center", marginBottom:"20px", fontSize:"0.7rem"}}>
                        Shanti Nagar Bhilai 3<br/>
                        Dist-Durg (C.G) Pin-490021<br/>
                        Email : Connect@bluepearlindustries.com<br/>
                        © COPYRIGHT BLUE PEARL INDUSTRIES
                    </div>

                }
            </div>
            </div>

            <div>  </div>
            
            
        </div>
        </footer>
    )
}