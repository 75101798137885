import axios from 'axios';
import { createRef, useContext, useEffect, useRef, useState } from 'react';
import './address.css'
import { json, useNavigate } from 'react-router-dom';
import { getData, getDataFromCollection } from '../Firebase/firestoreGet';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Address_ind from './address_ind';
import {
    GetCountries,
    GetState,
    GetCity,
    GetLanguages, //async functions
  } from "react-country-state-city";
import { AuthContext } from '../Firebase/AuthProvider';
import { db } from '../firebase';
import { addData } from '../Firebase/firestore';

const paymentURL= "https://suits-backend.vercel.app"

// http://localhost:1337
// https://suits-backend.vercel.app


function Address({setPage, loading, setLoading}) {

    const { user} = useContext(AuthContext);

    const navigate = useNavigate()

    const [inpts, setInputs] = useState([createRef(""), createRef(""), createRef(""), createRef(""),
                                        createRef(""), createRef("")])

    const [inpts_b, setInputs_b] = useState([createRef(""), createRef(""), createRef(""), createRef(""),
                                            createRef(""), createRef("")])

    const [address, setAddress] = useState()
    
    // const [order, setOrder] = useState({})
    
    const [stateid, setStateid] = useState(0);
    const [cityid, setCityid] = useState(0);

    const [stateid_b, setStateid_b] = useState(0);
    const [cityid_b, setCityid_b] = useState(0);

    const [country, setCounty] = useState( {"c":"India","id":101} );

    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [billing, setBilling] = useState(1)

    const [ addr, setAddr ] = useState();
  //////////
    
    useEffect(()=>{
        // JSON.parse(localStorage.getItem("sel"))
        let usrid = user.email
            if(!usrid){
                usrid = user.phoneNumber
            }
        const collection =  db.collection("Users").doc("Address").collection(usrid)

        getData(collection).then((data)=>{
            setAddress(data)
                console.log("address :) ", data)
        }).catch(()=>{

        })
    }, [])

    useEffect(() => {
        GetState(country.id).then((result) => {
            setStateList(result);
        });
      }, []);

    const addFunc = async(savedAdd = null, save= false)=>{

        // console.log(cityid, cityList[cityid], cityList)
        setLoading(true)

        let Address = {};

        if(savedAdd){
            Address = savedAdd;
        } else {
            Address = {
                "name":inpts[0].current.value,
                "last_name":inpts[1].current.value,
                "phone":inpts[2].current.value,
                "address":inpts[3].current.value +" , " + inpts[4].current.value,
                "state": stateList.find(o => o.id === stateid) ? stateList.find(o => o.id === stateid)["name"] : " ",
                "city": cityList.find(o => o.id === cityid) ? cityList.find(o => o.id === cityid)["name"] : " ",
                "pincode":inpts[5].current.value
            }
        }
        
        if(save){
            let usrid = user.email
            if(!usrid){
                usrid = user.phoneNumber
            }
            const collection =  db.collection("Users").doc("Address").collection(usrid)
            // console.log(address1, user.email)
            await addData(collection, "", Address )
            // console.log("Adding in DB")
            // notify("Address Saved Successfully");
            // console.log("Added to the DB", address)
        }

        window.localStorage.setItem( 'a', JSON.stringify(Address) )

        navigate("/checkout/payment")
    }

    

    
    return ( 
    <div className="Address">
        <div onClick={()=>{
            setPage(0)
        }}
            style={{"width":"fit-content", "marginTop":"1vh"}}
        >
            {/* <img src={arr}/> */}
            <u>Back to Cart</u>
        </div>
        <div className="txt-font delivery-address" style={{"marginBottom":"20px"}}>Select Delivery Address</div>

        <div className='saved-address'>
        <div className='test-acc'>
        <div class="accordion accordion-flush" id="accordionFlushExample">
                {address && address.length > 0 && <div className="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button show" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    Your Saved Address
                </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    {address && address.map((value, key)=>{
                        return(
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value={key}
                                    onChange={(e)=>{
                                        // selectAddress(address[e.target.value])
                                        setAddr( address[e.target.value] )
                                        
                                        // setData({...data,... {"address":address[e.target.value]}})
                                        // console.log("checked :", e.target.value, address[e.target.value])
                                    }}
                                />
                                <div class="form-check-label" for="exampleRadios1">
                                    {value.name} | {value["address"]}, {value["state"]}, {value["city"]},{value["pincode"]}, {value["phone"]}
                                </div>
                            </div>
                        )
                    })

                    }

                    <button className='Butt1 hori_center'
                        // disabled={true}
                        onClick={()=>{
                            if(addr){
                                //paymentVerification().then(()=>{})
                                addFunc( addr )
                                // displayRazorpay(data, 1).then((data)=>{console.log("opened")})
                            }else{
                                // Error toast
                                // console.log("Please select an address ")
                            }
                        }}
                    > <span>
                        Continue</span> </button>
                </div>
                </div>
            </div>}

            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"
                    disabled = {address && address.length > 0 ? false : true}
                >
                    
                    Add Delivery Address
                </button>
                </h2>
                <div id="flush-collapseTwo" class={address && address.length > 0 ? "accordion-collapse collapse" : "accordion-collapse collapse show"} aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <Address_ind 
                        country ={country}
                        inpts = {inpts}
                        stateList = {stateList}
                        stateid = {stateid} 
                        setStateid = {setStateid}
                        cityList = {cityList} 
                        cityid={cityid}
                        setCityid = {setCityid} 
                        setCityList = {setCityList}
                        addFunc = {addFunc}
                        type = {0}
                    />
                </div>
                </div>
            </div>
        </div>
        
        </div>
        </div>
        
        <div className='Billing_add billing-address'
            style={ !billing ?  {"width":"fit-content", "height":"fit-content", "border":"1px solid rgb(20, 33, 61, 0.5)", "padding":"5%"}:{}}
        >
            <b>Billing Address : Same as Delivery Address  &nbsp;</b>
            <input class="" type="checkbox" defaultChecked = {billing} id='billing_a'
                onChange={()=>{
                    setBilling(document.getElementById("billing_a").checked)
                    // console.log(document.getElementById("billing_a").checked)
                }}
                style={{"border":"1px solid black"}}
            />

            {!billing && 
                <div style={{"marginTop":"10px"}}>
                    <Address_ind 
                        country ={country}
                        inpts = {inpts_b}
                        stateList = {stateList}
                        stateid = {stateid_b} 
                        setStateid = {setStateid_b}
                        cityList = {cityList} 
                        cityid={cityid_b}
                        setCityid = {setCityid_b} 
                        setCityList = {setCityList}
                        addFunc = {addFunc}
                        type = {1}
                    />
                </div>}
        </div>

        
        

    </div> );
}

export default Address;