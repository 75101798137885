import Cost from "../cost_details";

import upi from '../../assets/logo/upi.svg'
import visa from '../../assets/logo/visa.svg'
import phonepe from '../../assets/logo/phonepe.svg'
import mastercard from '../../assets/logo/mastercard.svg'
import rupay from '../../assets/logo/rupay.svg'
import google_pay from '../../assets/logo/google_pay.svg'
import razorpay from '../../assets/logo/razorpay.svg'

import './payment.css'
import { useContext, useEffect, useState } from "react";
import Foot from "../../footer";
import { AuthContext } from "../../Firebase/AuthProvider";
import { paymentVerification, postOrder } from "../../Functions";


import aim from '../../assets/samples/anima.gif'
import Payments from "../payment";
import { useNavigate } from "react-router-dom";

const backendURL = "https://suits-backend.vercel.app"

function Payments_page() {

    const { user} = useContext(AuthContext);

    const navigate = useNavigate()

    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    const [pay, setPay] = useState(0)

    const [loading, setLoading] = useState(false)

    const [paymentDets, setPaymentDets] = useState()

    useEffect(()=>{
        const orders = JSON.parse(window.localStorage.getItem('sel'))
        if(!orders){
            navigate("/")
        }
    }, [])

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }
    
    async function displayRazorpay(data1, atype = 1) {

    // console.log("Address Data", data1)
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
        alert('Razorpay SDK failed to load. Are you online?')
        return
    }

    const headers = {
        "Content-Type" : "application/json"
    }

    const params ={
        "amount" : data1["price"]
    }

    // console.log(params)
    
    const resp = await fetch(`${backendURL}/razorpay`, { method: 'POST', headers:headers,body:JSON.stringify(params)});
    const data = await resp.json();

    const options = {
        key: 'rzp_live_Q72su2HX8hL88h',
        currency: data.currency,
        amount: data.amount.toString(),
        order_id: data.id,
        name: 'MAVIINCI',
        description: ` Total Items ${data1.items} `,
        image: `${backendURL}/logo.svg`,
        handler: function (response) {
            // paymentStatus({
            //     "id" : 2, 
            //     "status":"started"
            // })
            setPaymentDets({
                "razorpay_payment_id": response.razorpay_payment_id,
                "razorpay_order_id": response.razorpay_order_id
            })
            paymentVerification(response, atype).then((resp)=>{
                console.log("Payment Status : ", {
                    "id" : 0, 
                    "status": resp["status"] === 200
                })
                setPaymentDets( {
                    ...{
                            "razorpay_payment_id": resp.razorpay_payment_id,
                            "razorpay_order_id": resp.razorpay_order_id 
                        }, 
                    ...{
                        "id" : 0, 
                        "status": resp["status"] === 200,
                        price:data1.price
                    } 
                })
            })
        },
        modal:{
            ondismiss: async (reason) => {
                setLoading(false)
            }
        },
        customer : {

        },
        prefill: {
            name: data1.name ,
            email: user.email ,
            // phone: "8977664489", //user.phoneNumber //data1.phone,
            contact : data1.phone,
        },
        theme: {
            color: '#14213D', // Custom color for your checkout modal.
        }
    }
    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
    }


    useEffect(()=>{
        
        if(paymentDets && paymentDets["id"] === 0 && paymentDets["status"] === true){
            postOrder( {...paymentDets, ...{email:user.email, phone : user.phoneNumber}} ).then((resp)=>{
                if(resp && resp["status"] === 1){
                    setPaymentDets({ ...paymentDets, ...{"id":1} })
                }
            })
        }else if(paymentDets && paymentDets["id"] === 0 && paymentDets["status"] === false){
            setPaymentDets(...paymentDets, ...{
                "id": -1
            })
        }
        console.log(paymentDets)

    }, [paymentDets])





    return ( 
    
        <>

        {!paymentDets ?

                <>
                    <div className="pay_p">   

{loading && <div style={{"position":"fixed", "height":"100vh", "width":"100vw", "background":"rgb(0, 0, 0, 0.3)", "textAlign":"center", "top":"0", "left":"0", "zIndex":"1000"}}>
    <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'" 
        style={{"zIndex":"1", "top":"40vh", "position":"absolute"}}
        className="hori_center"
    />
    
</div>}

{/* {isMobile && <div className="hori_center FIT_W" style={{marginBottom:"30px"}}> <Cost pay={pay} 
displayRazorpay = {displayRazorpay}
setLoading = {setLoading}
/></div>} */}

<div className="pay_p_left">
<div style={{fontSize:"2rem"}}> Payment Options </div>
<div style={{fontSize:"0.8rem", marginBottom:"30px"}} > 100% Secure. All transactions are secure and encrypted. Choose any payment option below and continue </div>

<div className="pay_options">

        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                    onClick={()=>{
                        if(pay != 0){
                            setPay(0)
                        }
                    }}
                >
                    <div style={{position:"relative", "left":"30px"}}> Pay Full ( Razorpay ) </div>
                    <div className="pay_save">
                        Save 5%
                    </div>
                </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body" style={{textAlign:"center"}} >
                    
                    <div className="pay_logos hori_center">
                        <>Payment Options</><></>
                        <img src={upi} />
                        <img src={visa} />
                        <img src={mastercard} />
                        <img src={rupay} />
                        <img src={google_pay} />
                        <img src={phonepe} />
                        <> +10 </>
                    </div>
                    
                    <div style={{color:"#00a31b", fontWeight:"bold"}} > Offer Applied <b>5 %</b> off &nbsp; | &nbsp; You got Free Alteration </div>

                    <div>Pay full amount with your payment options. You will be directed to Razorpay for Payment</div>
                    
                    <div> 
                        <>Powered by</>
                        <img src={razorpay} style={{width:"100px"}} />
                    </div>
                    <br/>

                    <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">Alteration</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">Return and Cancellation policy</a>

                </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                    onClick={()=>{
                        if(pay != 1){
                            setPay(1)
                        }
                    }}
                >
                    <div style={{position:"relative", "left":"30px"}}> EMI Options </div>
                </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body" style={{textAlign:"center"}}>
                    Choose your EMI options and pay the amount in installements. 


                    <br/><br/>
                    <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">Alteration</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">EMI Policy</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">Return and Cancellation policy</a>
                </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                    onClick={()=>{
                        if(pay != 2){
                            setPay(2)
                        }
                    }}
                >
                    <div style={{position:"relative", "left":"30px"}}> Cash on Delivery (COD) </div>
                </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body" style={{textAlign:"center"}}>
                    
                    <div>You need to pay a token ammount of 500. This will be added to your total payable amount</div>
                    <div>Make the remaining payment at the time of Delivery</div>
                    <div style={{color:"#00a31b", fontWeight:"bold"}} > You got Free Alteration </div>
                    <br/>

                    <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">Alteration</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">COD Policy</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">Return and Cancellation policy</a>
                </div>
                </div>
        </div>
    </div>
</div>
</div>


                { (!isMobile) ?

                <Cost pay={pay} 
                displayRazorpay = {displayRazorpay}
                setLoading = {setLoading}
                />
                :
                <div className="hori_center FIT_W" style={{marginBottom:"30px", marginTop:"30px"}}> <Cost pay={pay} 
                displayRazorpay = {displayRazorpay}
                setLoading = {setLoading}
                />
                </div>}

                    </div>

                    <Foot/>    
                </>

                : 

                <Payments payment={paymentDets} />
        
        }
            
    </> );
}

export default Payments_page;