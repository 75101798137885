// import nextArr from '../../assets/ALeft.png'
import nextArr from '../../assets/icon/nextL.png'
import prevArr from '../../assets/icon/next.png'

import nextArr1 from '../../assets/icon/Larrow.png'
import prevArr1 from '../../assets/icon/Rarrow.png'

// import warning from '../../assets/icon/warning.svg'

import { fabrics, StylesFilter } from '../../Data';
import dummy from '../../assets/samples/body.png'
// import { dummyImg } from '../../Data'

import F from '../../assets/icon/F.svg'
import I from '../../assets/icon/info.png'
import S from '../../assets/icon/S.svg'

import logo_m from '../../assets/download.svg'
import default_shirt from '../../assets/samples/shirt/SHIRT.png'
// import shirt from '../../assets/samples/shirt/grey_shirt.png'
// import coat from '../../assets/samples/coat/red_coat.png'
// import jacket from '../../assets/samples/jacket/red_jacket.png'
// import pant from '../../assets/samples/pant/red_pant.png'

// import blue_coat from '../../assets/samples/coat/blue_coat.png'
// import blue_coat1 from '../../assets/samples/coat/blue_coat1.png'
// import s_coat from '../../assets/samples/coat/3BFP.png'

// import blue_back from '../../assets/samples/coat/0VBCOLO35.png'
// import s_back from '../../assets/samples/coat/0VBSPH75.png'

// import b_single from '../../assets/samples/coat/1BFNCOLO35.png'
// import s_single from '../../assets/samples/coat/1BFNSPH75.png'

// import v_b_single from '../../assets/samples/coat/DBVESTCOLO35.png'
// import v_s_single from '../../assets/samples/coat/VESTSPH75.png'

// import b_vest from '../../assets/samples/jacket/JACKET.png'
// import s_vest from '../../assets/samples/jacket/JACKET1.png'

// import b_pant from '../../assets/samples/pant/PANT.png'
// import s_pant from '../../assets/samples/pant/PANT1.png'

// import shirt from '../../assets/samples/shirt/SHIRT BODY.png'
// import color from '../../assets/samples/shirt/SPR.png'

// import s1 from '../../assets/samples/shirt/z/B COLLOR.png'
// import s2 from '../../assets/samples/shirt/z/BASE.png'
// import s3 from '../../assets/samples/shirt/z/CUFFLINK.png'
// import s4 from '../../assets/samples/shirt/z/SLEEV.png'
// import s5 from '../../assets/samples/shirt/z/pocket palla.png'
import close from '../../assets/icon/close.png'
import raymond from '../../assets/icon/Raymond.svg'

import curl from '../../assets/Arrow/curl.png'
import { useEffect, useRef, useState } from 'react'
import { getDatafromDoc } from '../../Firebase/firestoreGet'
import { db } from '../../firebase'
import {Types} from '../../Data'
import info from '../../assets/icon/info1.png'
import { useSwipeable } from 'react-swipeable';import ViewImages from './viewImages'
import { useNavigate } from 'react-router-dom'

  
export default function ViewFabric({sel, nav, setNav, setSel, loading,setLoading, state, single, setStylesAll, setMenuOpen,menuOpen,setMenuOpen2,menuOpen2, imgState, setImgState, jodh , mess, setMess}){

    const navigate = useNavigate()

    // const [imgState, setImgState] = useState(0)
    
    const [fabricType, setType] = useState("")
    const [viewDet, setCurrentDet] = useState("Coat")
    const [viewD, setViewD] = useState()
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedColor, setSelectedColor] = useState("");
    
    const [selectedFabric, setSelectedFabric] = useState(fabrics[0]);
    
    const [styleDes, setStyleDes] = useState()

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };
    const selectFabric = (fabric) => {
        setSelectedFabric(fabric);
        setShowDropdown(false); // Close dropdown after selecting fabric
    };
    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (imgState <=
                 state - 1) {
                setLoading(true);
                setImgState(curr => curr + 1);
            }
        },
        onSwipedRight: () => {
            if (imgState > 0) {
                setLoading(true);
                setImgState(curr => curr - 1);
            }
        }
    });
    const calPrice = (a)=>{  
        let p = 0
        Object.keys(a["fabric"]).forEach((element)=>{
            p+=Number(a["fabric"][element]["price"])
        }) 
        return p
    }
    

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 480);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    //handellling back button

    // function backMove () {
    //     if(nav === 1){
    //         setNav(0)
    //     }else {
    //         setNav(-1)
    //     }
        
    // }

    // useEffect(()=>{
    //     window.history.pushState( 'dummy', document.title, window.location.href )
    // }, [])

    // useEffect(()=>{
    //     if(nav === 1){window.history.pushState( 'dummy', document.title, window.location.href )

    //     window.addEventListener('popstate', backMove);
    //     console.log("someting")}

    //     return()=>{
    //         window.removeEventListener('popstate', backMove);

    //         if (window.history.state === 'dummy' && nav === 1) {
    //             window.history.back();
    //         }
        
    //     }

    // }, [])

    
    const getStyleDetails = ()=>{

        var StylesDets = {}
        // console.log("coming here in Styles des funct")
        if(sel && sel.style){
                
            Object.keys(sel["style"]).forEach((t)=>{
                StylesDets[t] = []
                Object.keys(sel["style"][t]).forEach((side)=>{
                    
                    var value = null;
                    var img = null;
                    sel["style"][t][side].forEach((val, k)=>{
                        if((t!=="Shirt") || (t === "Shirt" && val!= "BASE" && val!="SLEVE")){
                        
                        var r = null
                        
                        if(t === "Coat" && side === "F" && val[0] === "J"){
                            r = StylesFilter.find((filter) => filter.type === "Jodhpuri");
                            console.log("r:", r)
                        } else{
                            r = StylesFilter.find((filter) => filter.name === t && filter.pos === side && filter.n === k);
                        }
                        
                        
                        // console.log(r, side, t)
                            
                        // console.log("from inside", r["attr"])
                        for(let i = 0;i<r["attr"].length;i++){
                            if(value){break}
                            if( Array.isArray(r["attr"][i])){
                                for(let j = 0;j<r["attr"][i].length;j++){
                                    // console.log("of a type:", r["attr"][i][j])
                                    if(r["attr"][i][j]["value"] === val){
                                        value = r["attr"][i][j]["name"]
                                        img = r["attr"][i][j]["img"]
                                        break    
                                    }
                                }
                            }else{
                                if(r["attr"][i]["value"] === val){
                                    value = r["attr"][i]["name"]
                                    img = r["attr"][i]["img"]
                                    break
                                }
                            }
                        }
                        
                        if(value){
                            StylesDets[t].push( {"type":r["type"], "name":value, "img":img})
                        }
                         
                        //  `${r["type"]} : ${value}`
                        value = null
                        }
                    })
                })
            })

            setStyleDes(StylesDets)
            // console.log("From styles Description :", StylesDets)
                
            // StylesFilter
        }   
    }


    

    const DataCheck = ()=>{
        var Data = {...sel}
        var allow=  true;
        var c = 0;
        for(let i=0;i<Data["types"].length;i++){
            if(Data["fabric"] && !Data["fabric"][Data["types"][i]]){
                c = 1;
                if(i==0){
                    allow = false;
                    //Send an allert
                }else{
                    Data["farbic"][Data["types"][i]] = Data["farbic"][Data["types"][0]]
                }
                
            }
        }
        if(c === 1){setSel(Data)}
        return [Data, allow]
    }


    return(
        
        <div className="viewFabric" >

            {isMobile && (
                <a href="/" style={{textDecoration:"none"}}>
                <div className='Head'
                    onClick={()=>{
                        navigate("/")
                    }}
                >
                    <div className='logo_name'>
                        <img src={logo_m} style={{"width":"100%", "height":"100%", "objectFit":"contain"}}/>
                    </div>
                    {/* <div
                        style={{"height":"70px", "width":"40px", "position":"relative"}}
                    ><label className='logo_name'>Maviinci</label></div> */}
                    
                </div>
                </a>
            )

            }
            

            <div className="viewBody" {...handlers}>

                <div className="v-display"
                        onClick={()=>{
                            if(isMobile){
                                setMenuOpen(0)
                                setMenuOpen2(0)
                            }
                        }}
                >

                    <ViewImages 
                        sel = {sel}
                        single={single}
                        loading = {loading}
                        setLoading={setLoading}

                        imgState ={imgState}
                        setImgState = {setImgState}
                        
                        type = {0}
                        isMobile = {isMobile}

                        jodh = {jodh}
                    />
                    {/* // image scroll status section */}
                    

                        { nav !== -1 && !isMobile && <div className='image-scroll-sec hori_center'>
                            
                            <div className='img-scroll-dots'> 
                                <div className='round-left'>
                                </div>
                                <div className='i-s-d-m'>
                                    {state && [...Array(state+1).keys()].map((val, key)=>{
                                        return(
                                            <div className={key === imgState ? "i-s-dot1" : "i-s-dot"}></div>
                                        )
                                    })}
                                </div>
                                <div className='round-right'>
                                </div>
                            </div>
                        </div>}
                        <div {...handlers}></div>
                        
                        <div className="arrow-d">
            
                    <img src={isMobile ? nextArr1 : nextArr} className='v-arr-l'
                            onClick={()=>{
                                if(imgState > 0){
                                    // console.log("Clicking")
                                    setLoading(true)
                                }
                                setImgState((curr)=>(
                                curr > 0 ? curr - 1 : 0
                            ))}}
                        />
                    <img src={isMobile ? prevArr1 : prevArr} className='v-arr-r'
                            onClick={()=>{
                                if(imgState < state-1){
                                    // console.log("clicking")
                                    setLoading(true)
                                }
                                
                                setImgState((curr)=>(
                                curr < state ? curr + 1 : curr
                            ))}}
                            
                        />
                </div>
      

                </div>
                
                {!isMobile && <div className="v-des">
                    <div className="v-des_section txt-font1">
                        
                        
                        <label className="txt-font discount_p"
                            style = { sel && sel.discount && sel.discount>0 ? {}:{"display":"none"}}
                        >
                            {/* ₹ {sel.price} */}
                            
                            {(sel.price-sel.discount).toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })}
                        </label><br/>
                        
                        <label className="txt-font"
                             style = { sel && sel.discount && sel.discount>0 ? {"fontSize":"1.2rem", "textDecoration":"line-through", "opacity":"0.7"}:{}}
                        >
                            {/* ₹ {sel.price} */}
                            
                            {sel.price.toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })}
                        </label>
                        
                        
                        <label className="txt-font"
                             style = { sel && sel.discount && sel.discount>0 ? {"fontSize":"1.2rem", "opacity":"0.7", "color":"green"}:{"display":"none"}}
                        >
                            &nbsp; Save { Math.round((sel.discount / sel.price)*100)}%
                        </label>
                        
                        {!isMobile &&  <div style={{"fontSize":"0.8rem", color : "green"}} >
                            Estimated Delivery : { (new Date(  (new Date().setDate( (new Date()).getDate() +13 )) )).toDateString() }

                            <br/>

                        </div>}
                        
                        <div className="title-font1" >Custom</div>
                        <div className="title-font1">Suit Set#1</div>
                        
                        <div style={
                           sel.types.length ===2 ? {"width":"100%", "height":"70px", "position":"relative"}
                           : sel.types.length ===3 ? {"width":"100%", "height":"100px", "position":"relative"}
                           : {"width":"100%", "height":"150px", "position":"relative"}}
                           >

                        <div style={{"width":"fit-content", "position":"absolute", "right":"0", "textAlign":"left"}}> 
                        {sel.types && sel.types.map((val, key)=>{
                            return(<>
                                <label  style={{"fontSize":"0.8rem"}} >{val}</label> : <label style={{"fontSize":"0.8rem"}}>{sel["fabric"][val]["brand"]},</label>
                                <label style={{"fontSize":"0.8rem"}}>{sel["fabric"][val]["fabric"]}</label><br/>
                            </>)
                        })} 
                        </div>
                        </div>
                        
                        <a
                            className="a_tag"
                            type='button'
                            onClick={()=>{
                                setViewD(true)
                                getStyleDetails()
                            }}
                        >...More details</a>
                        <br/>

                        {/* {Object.keys(sel.style).map((val, k)=>{
                            return(
                                <><label>{sel.style[val]}</label><br/></>
                            )
                        })} */}
                        <div style={{"position":"relative", "width":"fit-content", "height":"fit-content"}} >
                        <button className="Butt1 v-checkout"
                            onClick={()=>{
                                window.history.pushState( 'dummy', document.title, window.location.href )
                                
                                if(nav == 0){
                                    
                                    setStylesAll({
                                        "Coat":{},
                                        "Vest":{},
                                        "Shirt":{},
                                        "Pant":{}
                                    })
                                }
                                const [Data, allow] = DataCheck()
                                
                                if(allow){
                                    sessionStorage.setItem("sel", JSON.stringify(Data))
                                    if(nav === 1){
                                        setNav(2)
                                        // navigate("/measurments")
                                    }else{
                                        // console.log("Enabling ")
                                        setNav((prevNav)=>
                                            prevNav = prevNav+1
                                        )
                                    }
                                    
                                }
                            }}
                        >
                            <span> 
                                {nav === 1 ? "Get Measured" : "Lets Dressup" }
                            </span>
                        </button>
                        {/* <div style={{"fontSize":"0.8rem"}} >
                            Estimated Delivery : { (new Date(  (new Date().setDate( (new Date()).getDate() +13 )) )).toDateString() }</div> */}
                        </div>
                    </div>
</div>}

                
                

{isMobile &&  (
    
    
        
    <div className="mobile-container">
        <div className="mobile-bottom-container">
            <div className='mobile_nav_bottom'>
                <div className='m-b-logos'>
                    <div className='m-b-logo-single'
                        onClick={()=>{setNav(0)}}
                    >

                        { mess && <div className='tut_arrow move'>
                            {/* <span>Start</span> */}
                            <img src={curl}/>
                        </div>}

                        <img  src = {F} style={{"height":"25px"}} className='hori_center'
                            onClick={() => {setMenuOpen2(!menuOpen2);

                                setMess(false)}
                            }
                        />
                        <b><label>Fabric</label></b>
                        {nav === 0 && 
                        <div className='m-b-logos-line'></div> }
                    </div>
                    <div className='m-b-logo-single'
                        onClick={()=>{setNav(1)}}
                    >
                        <img  src = {S} style={{"height":"25px"}} className='hori_center'
                            onClick={() => {setMenuOpen(!menuOpen);


                            setMess(false);
                                }
                            }
                        />
                        <b><label>Style</label></b>
                        {nav === 1 && 
                        <div className='m-b-logos-line'></div> }
                    </div>
                    <div className='m-b-logo-single'
                        onClick={()=>{
                            setViewD(true)
                            getStyleDetails();
                        }}
                    >
                        <img  src = {I} style={{"width":"25px"}} className='hori_center'/>
                        <b><label>info</label></b>
                    </div>
                </div>
            </div>
            <div className='money-custom'>
                <div className='custom-set-1'>
                    <b><div>Custom Suit #1</div></b>
                    
                    <div>
                        {sel && sel.discount && sel.discount>0 ? 
                        `${(sel.price-sel.discount).toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                        })}` : 
                        `${sel.price.toLocaleString('en-IN', {
                            maximumFractionDigits: 2,
                            style: 'currency',
                            currency: 'INR'
                        })}`}
                        {/* {(sel.price-sel.discount).toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                        })} */}
                        
                        <span 
                            style = { sel && sel.discount && sel.discount>0 ? {"color":"green", "marginLeft":"5px", "fontSize":"0.8"}:{"display":"none"}}
                        > { Math.round((sel.discount / sel.price)*100)}% off </span>
                    </div>
                    <div style={{"fontSize":"0.8rem"}} >
                            Estimated Delivery : { (new Date(  (new Date().setDate( (new Date()).getDate() +13 )) )).toDateString() }</div>
                </div>

                <button className="Butt1last v-checkout"
                onClick={() => {
                    setMess(false);
                    const [Data, allow] = DataCheck();
                    if (allow) {

                        sessionStorage.setItem("sel", JSON.stringify(Data));
                        if(nav === 1){
                            setNav(2)
                        }else{
                            setMenuOpen(true)
                            setNav((prevNav)=>
                                prevNav = prevNav+1
                            )
                        }
                    }
                }}
                >
                    {nav === 1 ? "Get Measured" : "Lets Dressup" }
                </button>

                
            </div>
            
        </div>
    </div>
)}

            </div>

            {viewD && <>
            <div className='opac'></div>
            <div className='view_details both_center'>
                <img src={close} style={{"position":"absolute", "right":"5px", "top":"5px"}}
                    onClick={()=>{
                        setViewD(false)
                    }}
                />
                <div className='v-modal-top hori_center txt-font1'>
                    {sel && sel.types.map((type_v)=>{
                        return(
                            <div className={ viewDet === type_v ?  'v-m-t-t' : 'v-m-t-t1'}
                                style={{"width":`${100/sel.types.length}%`}}
                                onClick={()=>{setCurrentDet(type_v);
                                    // getStyleDetails();
                                }}
                                type="button"
                            >{type_v}</div>
                        )
                    })}
                    
                    {/* <div className={ viewDet === 1 ?  'v-m-t-t' : 'v-m-t-t1'}
                        onClick={()=>{setCurrentDet(1) ; getStyleDetails(); }}
                        type="button"
                    >Style</div> */}
                </div>
                <div className='v-m-des txt-font1 hori_center'>
                        <div style={{"textAlign":"center", "marginBottom":"35px","fontSize":"1.5rem" ,"marginTop":"10px"}} ><b>Fabric Details</b></div>
                        <div style={{"width":"fit-content"}} className='hori_center'>
                            
                            <div className='v-m-d-f-comp'>

                                {/* <div><label className='both_center'><b>{viewDet}</b></label> </div> */}
                                
                                    
                                    <div className='v-m-d-f-img'><img src = {sel["fabric"][viewDet]["brandImg"]} style={{"width":"100%", "height":"100%", "objectFit":"cover"}} /></div>
                                
                                    <div 
                                        style={{"backgroundColor":"#14213D", "color":"white", "fontWeight":"bold", "padding":"0% 10px", "width":"fit-content"}}
                                    >
                                        <label className='verti_center'
                                            style={{"height":"fit-content", "margin":"0"}}
                                        >{sel["fabric"][viewDet]["fabric"]}</label>
                                    </div>
                                
                                
                            </div>
                            
                        </div>
                        <div className='styleDes-line hori_center'>  </div>
                        <div style={{"textAlign":"center", "marginTop":"10px", "fontSize":"1.5rem"}} ><b>Style Details</b></div><br/>
                        <div>
                            <div style={{"width":`${styleDes[viewDet].length*25}%`, "display":"flex", "position":"relative"}}
                                className='hori_center'

                            >
                                { styleDes && styleDes[viewDet].map((sty)=>{
                                    return(
                                        <div className='single_s_des'
                                            style={{"width":`${100/styleDes[viewDet].length}%`}}
                                        >
                                            <img src={sty.img} className='hori_center' />
                                            <b><label>{sty.type}</label></b>
                                            <label>{sty.name}</label>
                                            
                                        </div>
                                    )
                                })
                                }
                            </div>
                            
                        </div>
                        <br/><br/>
                        
                        
                        
                        {/* : (<>
                            {styleDes ? (<>

                                    {Object.keys(styleDes).map((typs, k)=>(
                                        <div className='s-comp-wrap'>
                                        <div className='eachStyleDes'>
                                        <div className='s-des-left'> 
                                        <b><label className='both_center'
                                                style={{"height":"fit-content"}}
                                            >{typs} </label></b>
                                        </div>
                                        <div className='s-des-right'>
                                            {Object.keys(styleDes[typs]).map((values, k1)=>{
                                                return(
                                                    <> 
                                                    {styleDes[typs][values].map((val1, k2)=>{
                                                        return(<div>
                                                            {val1.includes(typs) ? val1.split(typs)[1].trim() : val1 } 
                                                        </div>)
                                                    })

                                                    } </>
                                                )        
                                            } )
                                            }
                                        </div>
                                        
                                        </div>
                                        <div className='styleDes-line hori_center'>  </div>
                                        </div>
                                    ))}

                            </>) : <>Please Select Styles</>}
                        </>)} */}
                </div>
            </div>
            </>
            }
        </div>
    )
}