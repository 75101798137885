import Foot from "../footer";
import Terms from "../terms";

function Terms_C() {
    return (
    <div>
    <div className="Terms_main hori_center">
        <div className="title-font"> Terms and Conditions </div>
        <div className="txt-font1">Blue Pearl Industries Inc.</div>
        <br/>
        <Terms/>
        
    </div>
    <Foot/> 
    </div> 
    );
}

export default Terms_C;