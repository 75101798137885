import { useEffect, useState } from "react";
import Feel_left from "./feel_left";
import { db } from "../../firebase";
import { getData, getDocOnCondition } from "../../Firebase/firestoreGet";
import "../fabric.css";
import "./feel_fab.css";
import Fabric_modal from "./fabric_modal";
import { useNavigate } from "react-router-dom";

import curl from '../../assets/Arrow/curl_s.png'

import fabric_src from '../../assets/samples/SAMPLE.png'

function Feel_fabric() {
  const [Fabric, setFabric] = useState();

  const navigate = useNavigate()

  const [selection, setSelection] = useState();
  const [hoverDetils, setHoverDets] = useState({});
  const [sHover, setHover] = useState(-2);

  const [fabType, setFabType] = useState(0)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  const [mess, setMess] = useState(true)

  const getFabricDetails1 = async (id) => {
    // /Fabric/Suits/fabrics
    const coll = db.collection("Fabric").doc("Suits").collection("fabrics");
    const data = await getDocOnCondition(coll, "id", id);
    // // // // console.log("From function :", id, data)
    return data[0];
  };

  useEffect(() => {
    const collection = db
      .collection("Fabric")
      .doc("Suits")
      .collection("fabrics");

    getData(collection).then((data) => {
      setFabric(data);
      setSelection([data[0]]);
    });
  }, []);

  useEffect(() => {
    if (hoverDetils && Object.hasOwn(hoverDetils, "q")) {
      getFabricDetails1(hoverDetils["q"])
        .then((data) => {
          // console.log("From inside promise :", data)
          var Data = { ...data };
          var s = "";
          Data.color.forEach((ele) => {
            if (ele.length > 0) {
              s = s + ele;
            }
          });
          Data["color"] = s;
          setHoverDets(Data);
        })
        .catch((e) => {
          // console.log("from useEffect", e)
        });
    }
  }, [hoverDetils]);

  const changeFab = () => {
    const atkLfab = document.getElementById("atk-lfab");
    atkLfab.classList.add("atk-flab-slide");
  };

  const hideFab = () => {
    const atkLfab = document.getElementById("atk-lfab");
    atkLfab.classList.remove("atk-flab-slide");
  };

  const addToCart = ()=>{

    try{

      const data = JSON.parse(localStorage.getItem("sel"))

      var Sell = [...selection]

      for(let i = 0;i<Sell.length;i++){
        Sell[i]["item_type"] = 0
      }

      const D = [{
        item_type:0,
        src:fabric_src,
        name : "Fabric Sample Set",
        items : [...selection],
        id : (new Date().getTime()).toString(),
        price : selection.length>=2 ? 250 : 100
    }]
      if(data){

        localStorage.setItem('sel', JSON.stringify([...data, ...D]))
      }else{
        localStorage.setItem('sel', JSON.stringify([...D]))
      }
      navigate('/cart')

    }catch(e){
      console.log(e)
    }

  }

  return (
    <div className="feel_fabric">
      {sHover == -1 && hoverDetils && (
        <Fabric_modal
          hoverDetils={hoverDetils}
          setHoverDets={setHoverDets}
          setHover={setHover}
        />
      )}

      <div id="atk-lfab" className="atk-lfab">
        <button
          type="button"
          onClick={hideFab}
          class="btn-close atk-close"
          aria-label="Close"
        ></button>

        <Feel_left
          Fabric={Fabric}
          selection={selection}
          setSelection={setSelection}
          sHover={setHoverDets}
          hoverState={setHover}
          fabType = {fabType}
          setFabType = {setFabType}
        />
      </div>

      <div className="feel_right1">

        <div>
        
        <button
          type="button"
          class="btn atk-btn btn-primary"
          onClick={changeFab}
        >
          Select Fabric
        </button>

        {isMobile && mess && <div className='tut_arrow move'>
                            {/* <span>Start</span> */}
                            <img src={curl}/> Start !!
        </div>}

        
        </div>

        <div className="f-r-top">
          <div className="txt-font">
            {" "}
            Not sure on the selection of Fabric ?{" "}
          </div>
          <div>
            {" "}
            Experience the Fabric before you order. You can select fabrics and
            get a sample delivered to your home. Select Maximum 5 fabrics.
            Select 1 Fabric {Number(100).toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            })}. Select two or more at {Number(250).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
          })}{" "}
          </div>
        </div>

        <div className="" style={{ width: "fit-content", marginTop: "20px" }}>
          <div className="txt-font1">
            <b> Selected ({!selection ? 0 : selection.length}/5)</b>
          </div>
          <div
            className="atk-fabholder"
            style={{ width: "fit-content", display: "flex", gap: "10px" }}
          >
            <div className="FIT_W"
              style={!isMobile ? {display:"flex", gap:"10px", flexWrap:"wrap"} : {display:"flex", gap:"10px"} }
            >
            {selection &&
              selection.map((val, key) => {
                return (
                  <div className="fab_sel">
                    <img src={val.src} />
                    <div className="txt-small">
                      {" "}
                      <b>{val.name}</b> | <span>{val.brand}</span>{" "}
                    </div>
                  </div>
                );
              })}
              </div>
          </div>

          {/* <div style={{ width: "fit-content", display: "flex", gap: "10px" }}>
            {selection &&
              selection.slice(0, 3).map((val, key) => {
                return (
                  <div className="fab_sel">
                    <img src={val.src} />
                    <div>
                      {" "}
                      <b>{val.name}</b> | <span>{val.brand}</span>{" "}
                    </div>
                  </div>
                );
              })}
          </div> */}
          {/* <div
            style={{ width: "fit-content", display: "flex", gap: "10px" }}
            className="hori_center"
          >
            {selection &&
              selection.slice(3).map((val, key) => {
                return (
                  <div className="fab_sel">
                    <img src={val.src} />
                    <div>
                      {" "}
                      <b>{val.name}</b> | <span>{val.brand}</span>{" "}
                    </div>
                  </div>
                );
              })}
          </div> */}
        </div>

        <div className="fab_check">
          <div className="txt-font1">
            Fabrics Selected : {selection && selection.length}
          </div>
          <div style={{ display: "flex", gap: "30px" }}>
            <div>
              <label className="verti_center txt-font">
                {" "}
                Total {selection && selection.length > 1 ? 250 : 100}{" "}
              </label>
            </div>
            <button className="Butt1"
              onClick={()=>{
                addToCart()
              }}
            >
              <span>Checkout</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feel_fabric;