import { useEffect, useState } from 'react';
import './hero.css';

function Patti_home() {

    const caps = ["Custom Tailored", "Perfect Fit", "High Quality Material", "Fast Delivery"]
    const [active1, setActive1] = useState(0)
    useEffect(() => {
        setTimeout(
        () =>
            setActive1((prevIndex) =>
                prevIndex === caps.length - 1 ? 0 : prevIndex + 1
            ),
        1500
        );

        return () => {};
    }, [active1]);

    return ( <div
        className='patti'
    >
        {/* <div style={{"position":"relative", "width":"100%"}}> */}
        <label style={{"width":"fit-content"}} className='verti_center'>
                {caps[active1]}
        </label>
        {/* </div> */}
    </div>);
}

export default Patti_home;