import sample from '../assets/samples/s1.png'
import options from '../assets/icon/options.png'
import darrow from '../assets/icon/darrow.png'
import uparrrow from '../assets/icon/uparrow.png'

import { useState,useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ViewImages from '../Fabric/View/viewImages';
import body from '../assets/samples/body2.png'
import def_shirt from '../assets/samples/shirt/SHIRT.png'

import aim from '../assets/samples/anima.gif'
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

function Cartcard({item, deleteFromCart, setModalItem, toggle}) {
    

    const counter = useRef(0);
    
    const [expand, setExpand] = useState(0)
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

    const [load, setLoad] = useState(true)

    const [items, setItems] = useState()
    useEffect(()=>{
        if(item && Object.keys(item).includes("item_type") && item["item_type"] === 1 ){
            let a = []

            item.fabrics.forEach(element => {
                if(!a.includes(element.type)){
                    a.push(element.type)
                }
            });
            setItems(a)
        }
    }, [])
    useEffect(()=>{
        setLoad(true)
    }, [toggle])
    const imageLoaded = () => {
        counter.current += 1;
        let L
        
        if(Object.keys(item).includes("item_type")){
            L=1;
        }else{
            L = item.types.length;
        }

        if (counter.current >= L) {
            setLoad(false);
            counter.current = 0;
        }
    }

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 480);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
    <div style={{"width":"50vw"}}>
    <div className="cart-card cart-items-card"
        style={expand === 0 ? {"marginBottom":"30px"}:{}}
    >
        <Tooltip TransitionComponent={Zoom} 
        title= {Object.keys(item).includes("item_type") && item.item_type === 0 ? "Fabric Only" 
            : Object.keys(item).includes("item_type") && item.item_type === 1 ? "Preset": "Custom Suit"
            }
        >
        <div className='c-options' 
            style= {Object.keys(item).includes("item_type") && item.item_type === 0 ? {} : {"backgroundColor":"#14213D", "color":"white"}}
        >
            <div style={{"position":"relative", "width":"100%", "height":"100%"}}>
                <label className='FIT both_center'>
                    {Object.keys(item).includes("item_type") && item.item_type === 0 ? "F" 
                    : Object.keys(item).includes("item_type") && item.item_type === 1 ? "P": "C"
                    }
                </label>
            </div>
        </div>
                    
        </Tooltip>
        {/* <img src={options} className='c-options'/> */}

        <div className='cart-img'>

            {load && (<div className='f-loading'
                style={{position:"absolute", background:"#d3d3d3", height:"100%", width:"100%"}}
                >
                    <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'" style={{top:"0", transform:"scale(0.6)"}}/>
            </div>)}
            {/* <img src={sample} className='c-im'/> */}
            <div style={{"position":"relative", "width":"100%", "height":"100%", "objectFit":"contain"}}
                className='verti_center cart_img_pos '
            >
            
            {Object.keys(item).includes("item_type") && item.item_type === 1 ? 
                <>
                    {/* Presets */}
                    <img src={ item.src }
                        onLoad={imageLoaded}
                        style={{"objectFit":"contain"}}
                        className= {"v-display-img-full"}
                        />
                </> 
                : Object.keys(item).includes("item_type") && item.item_type === 0 ? 

                <>
                    {/* Fabric feel */}
                    <img src={ item.src }
                        onLoad={imageLoaded}
                        style={{"objectFit":"contain"}}
                        className= {"v-display-img-full"}
                        />
                </>

                // : Object.keys(item).includes("item_type") && item.item_type === 2 ? 
                
                // <>
                //     {item.fabric.single && item.fabric.single.length >0 && item.fabric.single[0].typ === 1 ? 
                //         (<>
                //             {item.fabric.single && item.fabric.single.length >0 && item.fabric.single[0]["src"].map((it)=>{
                //                 return(
                //                     <img src={ it }
                //                         onLoad={imageLoaded} 
                //                         className= {"v-display-img-full"}/>
                //                 )
                //             })

                //             }
                //         </>)
                //         : (
                //             <img src={ item.fabric.single[0].src }
                //             onLoad={imageLoaded} 
                //             className= {"v-display-img-full"}/>)
                //     }
                // </> 
                
                : <>
                    {(!load && !toggle )&& <img src={ body }
                                            // onLoad={imageLoaded} 
                        className= {"v-display-img-full"}/>}
                    {(!load && !toggle ) && <img src={ def_shirt }
                                            // onLoad={imageLoaded} 
                        className= {"v-display-img-full"}/>}
                    {item && item.types.map((val, key)=>{
                        return(
                            <img src={ item && item.fabric && Object.hasOwn(item.fabric, val) ? item.fabric[val]["src"] : "" }
                                onLoad={imageLoaded}
                                style={ 
                                     {"zIndex":50-item.fabric[val]["pos"]} } 
                                className= {item.types.includes("Pant") ? "v-display-img-full":"v-display-img"}/>
                        )
                    })}
                </>
            }
            
            
            </div>
        </div>
        
        <div className='cart-options'>
                
                <label className='c-o-e'
                    onClick={()=>{
                        const val = deleteFromCart(item.id)
                    }}
                    type="button"
                >Delete</label>

                {(Object.keys(item).includes("item_type") && item.item_type===0) &&  <label className='c-o-e'
                    onClick={()=>{
                        setModalItem({...{
                            "s":true
                        }, ...item})
                    }}
                    type="button"
                >More Info</label>}
                
                <div className='vertical-line'></div>
                {/* {((Object.keys(item).includes("item_type") && item.item_type!=0) || !Object.keys(item).includes("item_type") ) && <label className='c-o-e1'
                    onClick={()=>{
                        sessionStorage.setItem("sel",JSON.stringify(item))
                        navigate('/custom')
                    }}
                    type="button"
                >Edit item</label>} */}
        </div>
            
        <div className="cart-c-des verti_center">
            <div className='cart-des-left'>
                <div className="txt-font1 mobile-font ">
                    {item.name ? item.name: item.type}
                </div>
                
                {/* {Object.keys(item).includes("item_type") && item.item_type === 2 &&
                    <div className='mobile-item'> {item.fabric.brand}, {item.fabric.fabric} </div>
                } */}


                {!Object.keys(item).includes("item_type") && 
                
                    <div className='mobile-item'>
                        Items : &nbsp;
                        {item.types && item.types.map((val, ind)=>{
                            return(
                                <span> 
                                    {ind === item.types.length -1 ?
                                        `${val}` : `${val}, `
                                    }   
                                </span>
                                
                            )
                        })}
                    </div>
                }

                {Object.keys(item).includes("item_type") && item.item_type === 1 &&

                    <div className='mobile-item'> Items : &nbsp;
                        {items && items.map((val, ind)=>{
                            return(
                                <span> 
                                    {ind === items.length -1 ?
                                        `${val}` : `${val}, `
                                    }   
                                </span>
                            )
                        })}
                    </div>
                }

                {/* /// for fabrics  */}
                {Object.keys(item).includes("item_type") && item.item_type === 0 &&
                    <div className='mobile-item'>
                        {/* <span><img src ={item.brandImg}
                            className='card_brandImg'
                        /> {item.brand} </span><br/>
                        <span> {item.material} ; </span>
                        <span> {item.pattern} </span> */}
                        <span> Fabrics : {item.items.length}/5  </span> <br/>
                        <span style={{color:"green"}}> + Free Measuring tape  </span>
                    </div>

                }
                
                
                <div style={{color:"#00a31b"}} >Estimated Delivery : { (new Date(  (new Date().setDate( (new Date()).getDate() +13 )) )).toDateString() }</div>
                
                <div>
                    {/* {item["style"] && Object.keys(item.style).map((v, k)=>(

                        <>{item.style[v]}  </>
                    ))} */}
                    {/* Teal Green, Two-Piece, Custom Pocket Square */}
                </div>
                
            </div>
            
            {((Object.keys(item).includes("item_type") && item.item_type!=0) || !Object.keys(item).includes("item_type") ) ? <div className='cart-des-right txt-font1 mobile-font2'>
                {
                    !Object.keys(item).includes("item_type") && !toggle ?

                    ((Number(item.price)-Number(item.discount))).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    })
                    
                    : ((Number(item.price) - (Number(item.price)*Number(item.discount)/100))).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    })
                }
                <div style={{fontSize:"1rem", color:"green"}} > 
                    You Saved &nbsp;
                    {
                        !Object.keys(item).includes("item_type") && !toggle ?

                        
                            
                        `${ Math.round( (  Number(item.discount)/Number(item.price) )*100 ) } %` 
                        
                        : `${ Math.round(Number(item.discount)) } %`
                    }
                </div>
            </div> : <div className='cart-des-right txt-font1 mobile-font2'>
                {
                    
                    item.items.length > 2 ? Number(250).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    }) : Number(100).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    })
                }
            </div>}
            
        </div>
        {/* {isMobile && (
                expand === 0 ? (
                    <span className='c-det' onClick={() => setExpand(1)}>
                        View Details
                    </span>
                ) : (
                    <span className='c-det' onClick={() => setExpand(0)}>
                        View Details
                    </span>
                )
            )} */}

            {((Object.keys(item).includes("item_type") && item.item_type!=0) || !Object.keys(item).includes("item_type") ) && !isMobile && (
                <div className='c-det-mes' > Measurment Info <img src={darrow} className='' onClick={() => setExpand(expand === 0 ? 1 : 0)} /></div>
            )}
        
        

        {/* <div className='c-det'>Details <img src={plus} style={{"transform":"scale(0.7)"}}/> </div> */}
    </div> 
    { expand == 1 &&
    <div style={{"display":"flex"}}>
        {/* <div></div> */}
        <div className='c-m-modal'>
            <div>Measurments</div>
            {item && item["measurements"]["type"] == 0 ? (
                <div className='c-m-t-1'>
                <div>
                    Upper Body : {item["measurements"]["upper_body"]}
                </div>
                <div>
                    Lower Body : {item["measurements"]["lower_body"]}
                </div>
                </div>
            ) : (
                <div className='c-m-t-1'>
                    <div>
                        <b>Upper Body </b> <br/>
                        sleeve_length : {item["measurements"]["upper_body"]["sleeve_length"]}<br/>
                        chest_around : {item["measurements"]["upper_body"]["chest_around"]}<br/>
                        neck : {item["measurements"]["upper_body"]["neck"]}<br/>
                        shoulder_width : {item["measurements"]["upper_body"]["shoulder_width"]}<br/>
                        stomach : {item["measurements"]["upper_body"]["stomach"]}<br/>
                        length : {item["measurements"]["upper_body"]["length"]}<br/>
                        hip : {item["measurements"]["upper_body"]["hip"]}<br/>
                        bisep : {item["measurements"]["upper_body"]["bisep"]}<br/>
                    </div>
                    <div>
                        <b>Lower Body </b> <br/>
                        
                        leg_length : {item["measurements"]["lower_body"]["leg_length"]}<br/>
                        waist : {item["measurements"]["lower_body"]["waist"]}<br/>
                        hip : {item["measurements"]["lower_body"]["hip"]}<br/>
                        circle : {item["measurements"]["lower_body"]["circle"]}<br/>
                        thigh : {item["measurements"]["lower_body"]["thigh"]}<br/>
                        bottom : {item["measurements"]["lower_body"]["bottom"]}<br/>

                    </div>
                </div>
            )

            }

        </div>
    </div>
    }
    </div>
    );
}

export default Cartcard;